.risk_container {
    position: relative;
    width: 100%;
    background: linear-gradient(180deg, #00093c 0%, #0846a7 100%);
    .risk_img {
        position: absolute;
        top: 175px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        object-fit: fill;
        overflow: hidden;
        box-sizing: border-box;
        height: calc(100% - 175px);
    }
    .risk_title {
        margin-top: 126px;
        font-size: 34px;
        font-weight: 600;
        color: #ffffff;
        line-height: 36px;
        z-index: 10;
        text-align: center;
    }
    .risk_title_desc {
        font-size: 16px;
        text-align: center;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        margin-top: 15px;
        z-index: 10;
    }
    .risk_content_bg {
        display: flex;
        flex-direction: column;
        width: 1440px;
        z-index: 2;
        .risk_content_title_bg {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            .risk_content_title_item {
                display: flex;
                width: 384px;
                height: 76px;
                justify-content: center;
                align-items: center;
                // background: linear-gradient(270deg, #2798fb 0%, #0d6ff6 100%);
                margin-top: 84px;
                background-position: center center;
                background-size: 384px 76px;
                background-repeat: no-repeat;
                background-image: url("../../../../../../assets/solution/risk_data_bg.png");
                z-index: -2;
                .risk_content_title_item_icon {
                    width: 38px;
                    height: 39px;
                }
                .risk_content_title_item_title {
                    font-size: 26px;
                    // font-family:
                    //     PingFangSC,
                    //     PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 40px;
                    margin-left: 14px;
                }
            }
        }
    }
    .risk_model_bg {
        display: flex;
        flex-direction: row;
        width: 1440px;
        justify-content: space-between;
        z-index: 2;
        .risk_model_left_bg {
            display: flex;
            flex-direction: column;
            margin-top: 117px;
            .risk_model_left_item {
                position: relative;
                display: flex;
                width: 210px;
                height: 50px;
                background: linear-gradient(270deg, rgba(15, 53, 84, 0.23) 0%, rgba(149, 213, 245, 0.33) 100%);
                // opacity: 0.33;
                align-items: center;
                padding-left: 25px;
                margin-bottom: 40px;
                .risk_model_left_item_title {
                    font-size: 20px;
                    // font-family:
                    //     PingFangSC,
                    //     PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 28px;
                }
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 0;
                    background: linear-gradient(270deg, rgba(15, 53, 84, 0.3) 0%, rgba(149, 213, 245, 0.5) 100%);
                    z-index: -1;
                    transition: all 250ms;
                }
                &:hover::before {
                    width: 100%;
                }
            }
        }
        .risk_model_middle_bg {
            display: flex;
            flex-direction: row;
            .risk_model_middle_arrow {
                width: 80px;
                height: 160px;
                margin-top: 274px;
            }
            .risk_model_middle_content {
                display: flex;
                flex-direction: column;
                margin-top: 100px;
                align-items: center;
                .risk_model_middle_content_title {
                    font-size: 36px;
                    // font-family:
                    //     PingFangSC,
                    //     PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 50px;
                    background: linear-gradient(180deg, #a4dcff 0%, #4577af 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .risk_model_middle_content_level {
                    display: flex;
                    flex-direction: row;
                    width: 290px;
                    height: 30px;
                    justify-content: space-between;
                    margin-top: 30px;
                    .risk_model_middle_content_level_item {
                        display: flex;
                        width: 90px;
                        height: 30px;
                        font-size: 18px;
                        // font-family:
                        //     PingFangSC,
                        //     PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 25px;
                        justify-content: center;
                        align-items: center;
                        &:nth-child(1) {
                            background: linear-gradient(270deg, #2e9bfc 0%, #0571f6 100%);
                        }
                        &:nth-child(2) {
                            background: linear-gradient(270deg, #3ed9f2 0%, #13b2e8 100%);
                        }
                        &:nth-child(3) {
                            background: linear-gradient(270deg, #29ebaf 0%, #10db8b 100%);
                        }
                    }
                }
                .risk_model_middle_content_icon {
                    position: relative;
                    width: 500px;
                    height: 520px;
                    background-position: center center;
                    background-size: 500px 520px;
                    background-repeat: no-repeat;
                    background-image: url("../../../../../../assets/solution/lft.png");
                    .risk_model_middle_content_icon_ani {
                        position: absolute;
                        top: 60px;
                        left: 175px;
                        width: 150px;
                        height: 167px;
                        // background-color: red;
                        @keyframes flutter {
                            0% {
                                // transform: translateX(0);
                                opacity: 1;
                            }
                            50% {
                                // transform: translateX(100px);
                                opacity: 0.5;
                            }
                            100% {
                                // transform: translateX(0);
                                bottom: 100%;
                                opacity: 0;
                            }
                        }
                        .risk_bubble {
                            position: absolute;
                            border-radius: 50%;
                            background: #4cfdf5;
                            animation: flutter 6s infinite;
                            opacity: 0;
                            bottom: 0px;
                        }
                    }
                }
            }
        }
        .risk_model_right_bg {
            display: flex;
            flex-direction: row;
            .risk_model_right_warning {
                display: flex;
                flex-direction: column;
                align-items: center;
                .risk_model_right_warning_title {
                    font-size: 18px;
                    // font-family:
                    //     PingFangSC,
                    //     PingFang SC;
                    font-weight: 600;
                    color: #ffffff;
                    line-height: 25px;
                    height: 50px;
                    margin-top: 117px;
                }
                .risk_model_right_warning_item {
                    position: relative;
                    display: flex;
                    width: 130px;
                    height: 50px;
                    background: linear-gradient(270deg, rgba(15, 53, 84, 0.23) 0%, rgba(149, 213, 245, 0.33) 100%);
                    align-items: center;
                    padding-left: 25px;
                    margin-top: 40px;
                    .risk_model_right_warning_item_title {
                        position: absolute;
                        font-size: 20px;
                        // font-family:
                        //     PingFangSC,
                        //     PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 28px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 0;
                        background: linear-gradient(270deg, rgba(15, 53, 84, 0.3) 0%, rgba(149, 213, 245, 0.5) 100%);
                        z-index: -1;
                        transition: all 250ms;
                    }
                    &:hover::before {
                        width: 100%;
                    }
                }
            }
        }
    }
}
