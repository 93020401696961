.CompanyProfileTeamBuildingPage {
  padding-bottom: 80px;

  .list {
    flex: 1;
    display: flex;
    flex-direction: column;
    .item_left {
      display: flex;
      flex-direction: row;
      // justify-content: center;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 26px;
      border-bottom: 1px solid #dfdfdf;
      padding-top: 50px;
      transition: background-color 0.25s ease-in-out;

      &:hover {
        background-color: #f8f8f9;
      }
      .item_left_title {
        max-width: 687px;
        font-size: 16px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        // margin-right: 75px;
      }
      .item_left_img {
        width: 310px;
        height: 205px;
        // margin-right: 15px;
        position: relative;
        background: linear-gradient(270deg, #dee3ee 0%, #e9ecf4 50%, #dee3ee 100%);
        border: 6px solid #b3bcd2;
        vertical-align: middle;
        // box-shadow: 0px 13px 10px #82899b;

        //transition: all 1s linear;
        &.placeholder {
          opacity: 0;
        }
        .bg {
          position: absolute;
          top: 2px;
          left: 2px;
          width: 289px;
          height: 181px;
          background: url("../../../../../assets/images/huawen@2x.png") no-repeat center center / 100% 100%;
          //transition: all 1s linear;
        }
        .bgColor {
          //transition: opacity 1s linear;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, #2a6ac5 0%, #6795d7 49%, #2a6ac5 100%);
          opacity: 0;
        }
        .itemContent {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          .icon {
            width: 45px;
            height: 51px;
            margin: 33px auto 16px;
            //transition: all 1s linear;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .itemName {
            font-size: 17px;
            line-height: 24px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 auto;
            // font-family:
            //   PingFangSC,
            //   PingFang SC;
            font-weight: 600;
            color: #848da2;
            line-height: 24px;
            //transition: all 1s linear;
          }
        }
      }
      .item_left_img_shadow {
        width: 85%;
        height: 13px;
        background: #82899b;
        filter: blur(8px);
      }
    }

    .item_mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 345px;
      margin-top: 50px;
      .item_img_mobile {
        width: 250px;
        height: 165px;
        position: relative;
        background: linear-gradient(270deg, #dee3ee 0%, #e9ecf4 50%, #dee3ee 100%);
        border: 6px solid #b3bcd2;
        vertical-align: middle;
      }
      .item_title_mobile {
        width: 345px;
        font-size: 15px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 30px;
        // margin-bottom: 50px;
        margin-top: 30px;
      }
      .item_img_shadow_mobile {
        width: 230px;
        height: 11px;
        background: #82899b;
        filter: blur(8px);
      }
    }
  }
}
