.WalkingLantern {
  .aniContent {
    position: absolute;
    left: 0;
    top: 0;
    white-space: nowrap;
    animation: loop infinite linear 15s;

    .item {
      width: 125px;
      height: 106px;
      margin-top: 20px;
      display: inline-block;
      margin-left: 24px;
      vertical-align: middle;
    }
  }

  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}
