.CompanyProfileHonorPage {
  padding-bottom: 27px;
  .small {
    font-size: 14px;
    color: #919191;
    text-indent: 0;
  }
  .title {
    width: 335px;
    height: 120px;
    font-size: 30px;
    line-height: 30px;
    color: #333333;
    margin: -20px auto 0;
    background: url("../../../../../assets/images/HONOR@2x.png") no-repeat left top / 100% auto;
    text-align: center;
    padding-top: 38px;
    font-weight: bold;
  }

  .info {
    font-size: 15px;
    line-height: 30px;
    color: #333333;
    margin-top: 4px;
  }

  .list {
    // margin-top: 60px;
    // margin-top: 18px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 25px;

    .item {
      width: 356px;
      height: 251px;
      margin-right: 25px;
      margin-bottom: 24px;
      position: relative;
      overflow: hidden;

      .img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          transition: all 0.5s;
        }
      }

      

      .name {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #1c5db9;
        text-align: center;
        line-height: 30px;
        color: #ffffff;
        transition: all 0.5s ease;
        opacity: 0;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
      }

      &:hover {
        //.name {
        //  opacity: 1;
        //}
        cursor: pointer;

        .img {
          img {
            width: 125%;
            height: 125%;
          }
        }
      }
    }
  }
}
