.custom-tab {
    width: 100%;

    .tabs {
        padding: 0 110px;
        height: 50px;
        display: flex;
        font-family: PingFangSC-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        text-align: center;
        color: #919191;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background-color: #ebecf0;
        }
        .tab-item {
            flex: 1;
            cursor: pointer;
            &.active {
                font-family: PingFangSC-Semibold;
                font-size: 22px;
                font-weight: 500;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #333333;
                position: relative;
                &::after {
                    content: "";
                    width: 60px;
                    height: 3px;
                    background-color: #1c5db9;
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 1;
                }
            }
        }
    }
    .container {
        .tab-school-content {
            display: flex;
            flex-wrap: wrap;
            padding: 50px 0 30px;
            .tab-item {
                width: 171px;
                padding: 0 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-bottom: 50px;
                .logo {
                    img {
                        width: 90px;
                        height: 90px;
                        object-fit: cover;
                    }
                }
                .name {
                    font-family: PingFangSC-Medium;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #333333;
                    height: 40px;
                    overflow: hidden;
                    text-align: center;
                    margin-top: 18px;
                }
            }
        }
        .tab-partner-content {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding: 50px 0 180px;
          .tab-item {
            width: 342px;
            height: 102px;
            overflow: hidden;
            margin-bottom: 24px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          i{
            width: 342px;
          }
        }
        .tab-donated-content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 50px 0 80px;
            .column {
                width: 380px;
                .column-card {
                    margin-bottom: 30px;
                    .title-box {
                        height: 60px;
                        background-color: #f4f5fc;
                        font-family: PingFangSC-Semibold;
                        font-size: 20px;
                        font-weight: 500;
                        font-stretch: normal;
                        letter-spacing: 0px;
                        color: #333333;
                        text-align: center;
                        line-height: 60px;
                    }
                    .card-content {
                        box-sizing: border-box;
                        border: solid 1px #f4f5fc;
                        padding: 30px 40px 30px 50px;
                        font-family: PingFangSC-Regular;
                        font-size: 16px;
                        font-weight: normal;
                        font-stretch: normal;
                        letter-spacing: 0px;
                        color: #333333;
                        div {
                            margin-bottom: 30px;
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
