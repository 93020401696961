.DigitalMedicinePage {
  .headTitle {
    font-size: 34px;
    color: #333333;
    line-height: 34px;
    font-weight: bold;
    text-align: center;
  }

  .headInfo {
    font-size: 15px;
    line-height: 27px;
    color: #333333;
    text-align: center;
    margin-top: 27px;
  }

  .digitalMedicinePageContent {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    flex-wrap: wrap;
    margin-bottom: 60px;

    .item {
      width: 440px;
      height: 290px;
      position: relative;
      margin-bottom: 20px;

      .itemContent, .hover {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 24px;
        color: #ffffff;
        line-height: 290px;
        text-align: center;
        font-weight: bold;

        &.hover {
          opacity: 0;
          transition: all .5s ease;
          background: #1c5db9;

          .title {
            line-height: 33px;
            margin-top: 58px;
          }

          .info {
            width: 290px;
            font-size: 16px;
            line-height: 28px;
            color: #ffffff;
            margin: 20px auto 0;
            text-align: left;
            font-weight: normal;
          }
        }
      }

      &:nth-child(1) {
        background: url("../../../../../assets/images/medical_img1@2x.png") no-repeat center center / 100% 100%;
      }

      &:nth-child(2) {
        background: url("../../../../../assets/images/medical_img2@2x.png") no-repeat center center / 100% 100%;
      }

      &:nth-child(3) {
        background: url("../../../../../assets/images/medical_img3@2x.png") no-repeat center center / 100% 100%;
      }

      &:nth-child(4) {
        background: url("../../../../../assets/images/medical_img4@2x.png") no-repeat center center / 100% 100%;
      }

      &:hover {
        .hover {
          opacity: 1;
        }
      }
    }
  }
}
