.CompanyProfileHistoryPage_mobile {
    display: flex;
    flex-direction: column;
    // width: 375px;
    max-width: 430;
    position: relative;
    align-items: center;
    margin-right: 15px;
    margin-left: 15px;
    .list {
        padding-top: 40px;
        padding-bottom: 40px;

        .item {
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            //margin-top: -90px;

            .infoBox {
                .news {
                    .newsItem {
                        display: flex;
                        flex-direction: row;
                        line-height: 26px;
                        color: #333333;
                        margin-bottom: 20px;
                        .newsItem_title {
                            width: 70px;
                            font-size: 18px;
                            font-family:
                                PingFangSC,
                                PingFang SC;
                            font-weight: 600;
                            color: #333333;
                            margin-left: 15px;
                        }
                        .newInfoItem {
                            font-size: 16px;
                            font-family:
                                PingFangSC,
                                PingFang SC;
                            font-weight: 400;
                            color: #333333;
                            margin-bottom: 10px;
                            max-width: 300px;
                        }
                    }
                }
            }
        }
    }
    .cphp_right_bg {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        // width: 375px;
        margin-top: 30px;
        margin-left: 15px;
        margin-right: 15px;
        .cphp_right_top_icon {
            width: 24px;
            height: 24px;
        }
        .cphp_right_title {
            // width: 100px;
            // height: 60px;
            font-size: 30px;
            font-family:
                PingFangSC,
                PingFang SC;
            font-weight: 600;
            color: #cccccc;
            margin-right: 8px;
            // line-height: 60px;
        }
        .cphp_right_selected_title {
            // width: 120px;
            // height: 80px;
            font-size: 40px;
            font-weight: bold;
            color: #1c5db9;
            margin-left: 20px;
            margin-right: 20px;
            // line-height: 80px;
        }
    }
}
