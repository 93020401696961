.RecruitmentInformation {
  position: relative;
  margin-bottom: 40px;

  .title {
    font-size: 30px;
    color: #333333;
    line-height: 42px;
    font-weight: bold;
  }

  .info {
    line-height: 24px;
    font-size: 17px;
    color: #333333;
    margin-top: 10px;
  }

  .hideContent {
    font-size: 15px;
    line-height: 29px;
    color: #919191;
    margin-top: 8px;
  }


  .moreButtonBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    .moreButton {
      display: flex;
      align-items: center;
      cursor: pointer;

      .text {
        font-size: 14px;
        letter-spacing: 1px;
        color: #1c5db9;
        line-height: 20px;
        margin-right: 8px;
      }

      .icon {
        width: 12px;
        height: 12px;
        background: url("../../../../assets/images/show@2x.png") no-repeat center center / 100% 100%;

        &.active {
          background: url("../../../../assets/images/hide@2x.png") no-repeat center center / 100% 100%;
        }
      }
    }
  }

  .showContent {
    background: #f2f3f5;
    padding: 20px 0;
    margin-top: 16px;

    .inlineTitle {
      font-size: 20px;
      color: #333333;
      line-height: 29px;
      position: relative;
      padding: 10px 0;
      margin-left: 20px;
      font-weight: bold;

      &::before {
        position: absolute;
        left: 0;
        bottom: 0;
        content: '';
        display: block;
        width: 32px;
        height: 2px;
        background: #1c5db9;
      }
    }

    .inlineText {
      margin-left: 20px;
      font-size: 15px;
      color: #333;
      line-height: 29px;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .copy {
      margin-left: 20px;
      display: flex;
      align-items: center;

      .inlineBodyTitle {
        font-size: 17px;
        line-height: 29px;
        margin-right: 30px;
      }

      .copyButton {
        padding-left: 28px;
        line-height: 21px;
        font-size: 15px;
        color: #1c5db9;
        background: url("../../../../assets/images/copy@2x.png") no-repeat left center / 22px 22px;
        cursor: pointer;
      }
    }
  }

  .hide {
    display: none;
  }

  .show {
    display: block;
  }
}
