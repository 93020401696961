.CompanyProfileTeamBuildingModal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background: rgba(0, 0, 0, .6);
  display: flex;
  overflow: hidden;
  z-index: 10000;


  .modalContent {
    width: 1000px;
    height: 700px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #979797;
    position: relative;

    .close {
      position: absolute;
      right: 30px;
      top: 30px;
      width: 26px;
      height: 26px;
      background: url("../../assets/images/close@2x.png") no-repeat center center / 100% 100%;
      cursor: pointer;
    }

    .title {
      font-size: 26px;
      color: #333333;
      line-height: 37px;
      text-align: center;
      margin: 60px 0 30px;
    }

    .CompanyProfileTeamBuildingSwiper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 35px;

      .CompanyProfileTeamBuildingSwiperRight, .CompanyProfileTeamBuildingSwiperLeft {
        width: 40px;
        height: 186px;
        background: #c9d0de url("../../assets/images/left_page@2x.png") no-repeat center center / 30px 30px;
        cursor: pointer;

        &.CompanyProfileTeamBuildingSwiperRight {
          background: #c9d0de url("../../assets/images/right_page@2x.png") no-repeat center center / 30px 30px;
        }
      }

      .CompanyProfileTeamBuildingSwiperContent {
        width: 800px;
        height: 455px;
        background: gray;

        .CompanyProfileTeamBuildingSwiperBox {
          width: 100%;
          height: 100%;
        }
      }
    }

    .paging {
      //background: gray;
      height: 4px;
      text-align: center;
      margin: 20px 0;

      .swiper-pagination-bullet {
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        width: 20px;
        height: 4px;
        background: #f1f1f1;

        &.swiper-pagination-bullet-active {
          background: #1c5db9;
        }
      }
    }

    .text {
      width: 794px;
      font-size: 17px;
      line-height: 21px;
      color: #333333;
      margin: 0 auto;
    }
  }
}
