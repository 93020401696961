.CompanyProfileManagementTeamPage {
  padding-bottom: 82px;

  .list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .item {
      width: 446px;
      padding: 14px;
      background: #f2f3f5;
      margin-bottom: 8px;
      cursor: pointer;

      .left {
        display: flex;

        .head {
          width: 150px;
          height: 150px;
          //overflow: hidden;
          position: relative;

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all .5s ease;
          }

          &:before {
            display: block;
            content: '';
            position: absolute;
            left: 116px;
            top: 116px;
            width: 40px;
            height: 40px;
            background-color: #f2f3f5;
            border: solid 6px #1c5db9;
          }
        }

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .name {
            font-size: 20px;
            color: #1c5db9;
          }

          .level {
            text-align: center;
            font-size: 15px;
            line-height: 22px;
            color: #333333;
            margin-top: 10px;
          }
        }
      }

      .right {
        margin-top: 42px;
        font-size: 15px;
        line-height: 25px;
        color: #333333;
      }

      &.boos {
        width: 100%;
        display: flex;
        padding-bottom: 26px;

        .left {
          flex: 1;
        }

        .right {
          width: 460px;
          margin: 0 20px 0 60px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }

      &:hover{
        .left{
          .head{
            img{
              width: 168px;
              height: 168px;
            }
          }
        }
      }
    }
  }
}
