.NewsPage {
  padding-bottom: 80px;
  // // padding-top: 80px;
  // min-height: 750px;
  background: white;
  position: relative;

  .list {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    flex-wrap: wrap;

    .item {
      width: 688px;
      height: 180px;
      display: flex;
      margin-bottom: 60px;
      cursor: pointer;
      // background-color: white;

      .icon {
        width: 280px;
        height: 180px;
        border-radius: 5px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.5s ease;
          transform-origin: center center;
        }
      }

      .bottomIcon {
        margin-top: 15px;
        width: 24px;
        height: 4px;
        background: url("../../../../assets/images/arrow_icon.png") no-repeat center center / 100% 100%;
      }

      .infoBox {
        position: relative;
        margin-left: 25px;
        width: 383px;
        transition: 0.5s ease;

        .title {
          // white-space: nowrap;
          overflow: hidden;
          // text-overflow: ellipsis;
          font-size: 22px;
          font-family:
            PingFangSC,
            PingFang SC;
          font-weight: 600;
          color: #333333;
          line-height: 30px;
          margin-top: 10px;
        }

        .des {
          margin-top: 25px;
          overflow: hidden;
          text-overflow: ellipsis;

          font-size: 14px;
          font-family:
            PingFangSC,
            PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .more_bg {
          position: absolute;
          display: flex;
          align-items: center;
          display: flex;
          flex-direction: row;
          bottom: 0;
          right: 0;
          cursor: pointer;
          .more_title {
            font-size: 14px;
            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: 400;
            color: #919191;
            line-height: 20px;
            transition: transform 0.3s ease;
          }
          .more_img {
            margin-left: 5px;
            width: 12px;
            height: 12px;
            background-image: url("../../../../assets/home/more.png");
            background-size: cover;
            background-position: 0 0;
            background-repeat: no-repeat;
            transition: transform 0.3s ease;
          }
        }
      }

      &:hover {
        .icon {
          img {
            transform: scale(1.1);
          }
        }

        .infoBox {
          // margin-top: 7px;

          .title {
            color: #1c5db9;
          }

          .des {
            color: #1c5db9;
          }
          .more_bg .more_title {
            color: #1c5db9;
            transform: translateX(5px);
          }
          .more_bg .more_img {
            background-image: url("../../../../assets/home/more_blue.png");
            transform: translateX(10px);
          }
        }
      }
    }
  }

  .Pagination {
    margin-top: 30px;
    text-align: center;
  }
  .more_button_page_bg {
    display: none;
  }
}
