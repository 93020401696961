.risk_container_mobile {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    // width: 100vw;
    max-width: 430px;
    background: linear-gradient(180deg, #00093c 0%, #0846a7 100%);

    .risk_title {
        margin-top: 40px;
        font-size: 20px;
        font-weight: 600;
        color: #ffffff;
        z-index: 10;
    }
    .risk_title_desc {
        font-size: 13px;
        font-weight: 400;
        color: #ffffff;
        margin-top: 8px;
        z-index: 10;
    }
    .risk_content_bg {
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 2;
        .risk_content_title_bg {
            display: flex;
            width: 100%;
            flex-direction: row;
            justify-content: center;
            // justify-content: space-between;
            .risk_content_title_item {
                display: flex;
                width: 154px;
                height: 36px;
                justify-content: center;
                align-items: center;
                // margin-top: 30px;
                background-position: center center;
                background-size: 154px 36px;
                background-repeat: no-repeat;
                background-image: url("../../../../../../assets/solution/risk_data_bg.png");
                z-index: -2;
                .risk_content_title_item_icon {
                    width: 20px;
                    height: 20px;
                }
                .risk_content_title_item_title {
                    font-size: 14px;
                    font-weight: 600;
                    color: #ffffff;
                    margin-left: 14px;
                }
                &:nth-child(2) {
                    margin-left: 27px;
                }
            }
        }
    }
    .risk_model_bg {
        display: flex;
        flex-direction: column;
        width: 100%;
        z-index: 2;
        .risk_model_left_bg {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 50px;
            justify-content: center;
            .risk_model_left_item {
                position: relative;
                display: flex;
                max-width: 160px;
                height: 32px;
                padding-left: 20px;
                padding-right: 20px;
                margin-left: 25px;
                background: linear-gradient(270deg, rgba(15, 53, 84, 0.23) 0%, rgba(149, 213, 245, 0.33) 100%);
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                .risk_model_left_item_title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                }
                &:nth-child(1) {
                    margin-left: 0px;
                }
            }
        }
        .risk_model_middle_bg {
            display: flex;
            flex-direction: column;
            align-items: center;
            .risk_model_middle_arrow {
                width: 70px;
                height: 35px;
            }
            .risk_model_middle_title {
                font-size: 22px;
                font-weight: 600;
                color: #ffffff;
                line-height: 30px;
                background: linear-gradient(180deg, #a4dcff 0%, #4577af 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .risk_model_middle_content_level {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 24px;
                justify-content: center;
                // justify-content: space-between;
                margin-top: 30px;
                .risk_model_middle_content_level_item {
                    display: flex;
                    width: 90px;
                    height: 24px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #ffffff;
                    justify-content: center;
                    align-items: center;
                    margin-left: 10px;
                    &:nth-child(1) {
                        background: linear-gradient(270deg, #2e9bfc 0%, #0571f6 100%);
                        margin-left: 0;
                    }
                    &:nth-child(2) {
                        background: linear-gradient(270deg, #3ed9f2 0%, #13b2e8 100%);
                    }
                    &:nth-child(3) {
                        background: linear-gradient(270deg, #29ebaf 0%, #10db8b 100%);
                    }
                }
            }
        }
        .risk_model_right_bg {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-bottom: 60px;
            .risk_model_right_warning {
                display: flex;
                flex-direction: column;
                align-items: center;
                .risk_model_right_warning_title {
                    font-size: 14px;
                    font-weight: 600;
                    color: #ffffff;
                    // line-height: 25px;
                    // height: 50px;
                    margin-top: 24px;
                }
                .risk_model_right_warning_item {
                    position: relative;
                    display: flex;
                    width: 100px;
                    height: 32px;
                    justify-content: center;
                    align-items: center;
                    background: linear-gradient(270deg, rgba(15, 53, 84, 0.23) 0%, rgba(149, 213, 245, 0.33) 100%);
                    align-items: center;
                    margin-top: 20px;
                    .risk_model_right_warning_item_title {
                        position: absolute;
                        font-size: 14px;
                        font-weight: 400;
                        color: #ffffff;
                        // line-height: 28px;
                    }
                }
            }
        }
    }
}
