.saas_container_mobile {
    display: flex;
    // width: 375px;
    max-width: 430px;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        line-height: 36px;
        margin-top: 40px;
        margin-bottom: 20px;
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
    }
    .item {
        display: flex;
        flex-direction: column;
        // width: 345px;
        height: 340px;
        background: #ffffff;
        border-radius: 3px;
        align-items: center;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 20px;
        .item_title {
            display: flex;
            height: 50px;
            width: 100%;
            justify-content: center;
            align-items: center;
            background: linear-gradient(270deg, #f8fafe 0%, #dbe6fd 100%);
            font-size: 19px;
            font-weight: bold;
            color: #333333;
            line-height: 26px;
        }
        // 管理平台
        .left_bg {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .left_title {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 220px;
                height: 38px;
                background: #e9eff8;
                border-radius: 19px;
                font-size: 17px;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
                margin-top: 50px;
            }

            .left_content {
                display: flex;
                flex-direction: row;
                margin-top: 50px;
                .left_content_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 78px;
                    height: 86px;
                    border: 1px solid #e4e8f1;
                    margin-right: 26px;
                    .left_content_item_icon {
                        width: 30px;
                        height: 30px;
                    }
                    .left_content_item_title {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        margin-top: 5px;
                    }
                    &:nth-child(3) {
                        margin-right: 0px;
                    }
                }
            }
        }
        // 身份
        .middle_bg {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            .middle_item {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                .middle_item_icon {
                    width: 60px;
                    height: 60px;
                }
                .middle_item_title {
                    font-size: 14px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    margin-top: 10px;
                }
            }
            .middle_student {
                position: relative;
                display: flex;
                width: 60px;
                height: 60px;
                background: #dbe7fd;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 18px;
                font-weight: bold;
                color: #1c5db9;
            }
        }
        // 应用场景
        .right_bg {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 20px;
            margin-right: 20px;
            .right_content_item {
                display: flex;
                flex-direction: row;
                align-items: center;

                width: 101px;
                margin-bottom: 23px;
                .right_content_item_point {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #1c5db9;
                }
                .right_content_item_title {
                    margin-left: 8px;
                    font-size: 15px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21px;
                }
                &:nth-child(-n + 3) {
                    margin-top: 46px;
                }
                &:nth-child(3n + 2) {
                    justify-content: center;
                }
                &:nth-child(3n + 3) {
                    justify-content: flex-end;
                }
            }
        }
    }
}
