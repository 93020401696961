.partnerCase {
  margin-top: 56px;
  padding-bottom: 120px;
  .partnerCase_items {
    // margin-top: 10px;
    display: flex;
    // cursor: pointer;
    margin-bottom: 30px;
    .partnerCase_item {
      display: flex;
      justify-content: center;
      margin-right: 30px;
      font-size: 28px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 600;
      color: #333333;
      opacity: 0.3;
      line-height: 36px;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 20%;
        bottom: -10px;
        width: 0%;
        height: 1px;
        background: #333;
        transition: all 0.5s linear;
      }
      &.active {
        font-size: 28px;
        color: #333333;
        opacity: 1;
        &:before {
          // width: 60%;
          background: #333333;
        }
      }
    }
  }

  .partnerCaseContent {
    .ani {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 126px;
    }
  }
}