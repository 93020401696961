.CompanyProfileHistoryPage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .list {
    padding-top: 40px;
    padding-bottom: 57px;

    .item {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      //margin-top: -90px;

      .infoBox {
        .title {
          margin-top: 30px;
          margin-bottom: 16px;
          font-size: 34px;
          line-height: 40px;
          color: #333333;
          font-weight: bold;
        }

        .news {
          .newsItem {
            display: flex;
            flex-direction: row;
            font-size: 15px;
            line-height: 26px;
            color: #333333;
            margin-bottom: 20px;
            .newsItem_title {
              width: 70px;
              font-size: 15px;
              font-family:
                PingFangSC,
                PingFang SC;
              font-weight: 600;
              color: #333333;
            }
            .newInfoItem {
              // width: 700px;
              font-size: 16px;
              font-family:
                PingFangSC,
                PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-bottom: 10px;
            }
          }
        }
      }

      .icon {
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 0;
        margin-left: -55px;
        width: 110px;
        display: flex;
        flex-direction: column;
        height: 100%;
        border-radius: 110px;

        .img {
          width: 90px;
          height: 90px;
          overflow: hidden;
          border-radius: 90px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .line {
          flex: 1;
          width: 1px;
          background: #d5dae2;
          margin: 0 auto;
        }
      }

      &.right {
        justify-content: flex-end;
      }

      &.active {
        .icon {
          .img {
            width: 106px;
            height: 106px;
            background-color: #1c5db9;
            border: solid 4px #d6e3f5;
            border-radius: 110px;

            img {
              display: block;
              width: 90px;
              height: 90px;
              margin: 4.5px auto 0;
            }
          }
        }
      }

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        .line {
          display: none;
        }
      }
    }
  }
  .cphp_right_bg {
    display: flex;
    flex-direction: column;
    margin-left: 40px;
    align-items: center;
    .cphp_right_top_icon {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
    .cphp_right_title {
      width: 144px;
      height: 84px;
      font-size: 60px;
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 600;
      color: #cccccc;
      line-height: 84px;
      cursor: pointer;
    }
    .cphp_right_selected_title {
      width: 183px;
      height: 107px;
      font-size: 76px;
      font-weight: bold;
      color: #1c5db9;
      line-height: 107px;
      // -webkit-text-stroke: 1px #1c5db9;
      // text-stroke: 1px #1c5db9;
      cursor: pointer;
    }
  }
}
