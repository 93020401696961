.technology_container_mobile {
    display: flex;
    background: #f8f8f8;
    align-items: center;
    flex-direction: column;
    // margin-bottom: 120px;
    max-width: 430px;
    .technology_title {
        margin-top: 50px;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
    }
    .technology_title_desc {
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        line-height: 18px;
        margin-top: 8px;
    }
    .technology_content_bg {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // width: 345px;
        justify-content: space-between;
        margin-left: 15px;
        margin-right: 15px;
        .technology_content_item {
            width: 150px;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #ededed;
            background-color: rgba($color: #ededed, $alpha: 0);
            margin-bottom: 70px;

            .technology_content_item_icon {
                width: 70px;
                height: 70px;
            }
            .technology_content_item_title {
                font-size: 18px;
                font-weight: 600;
                color: #333333;
                line-height: 25px;
                margin-top: 20px;
            }
            .technology_content_item_desc {
                font-size: 12px;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-top: 20px;
            }
        }
    }
}
