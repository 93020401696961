* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  background: #142c4f;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro,Menlo,Monaco,Consolas,"Courier New",monospace;
}

pre,
article,
body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}

a {
  color: #20b7bc;
  text-decoration: none;
}

img {
  width: 100%;
  height: 100%;
  vertical-align: top;
  object-fit: cover;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
dl,
dt,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: normal;
}

a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  outline: none;
  background: none;
  text-decoration: none;
}

input {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

pre {
  margin: 0;
  padding: 0;
}