.AchievementSwiper {
  padding-bottom: 90px;

  .swiper {
    height: 260px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftButton, .rightButton {
      width: 40px;
      height: 186px;
      background-color: #e4e8f1;
      background-image: url("../../../../assets/images/left_page@2x.png");
      background-position: center center;
      background-size: 30px 30px;
      background-repeat: no-repeat;
      cursor: pointer;
      transition: all .5s ease;

      &.rightButton {
        background-image: url("../../../../assets/images/right_page@2x.png");
      }

      &:hover {
        background-color: #d8dff0;
      }
    }

    .swiperContent {
      flex: 1;
      overflow: hidden;
      height: 260px;
      position: relative;

      .swiperContentBox {
        width: 100%;
        height: 100%;

        .AchievementSwiper_Box {
          width: 100%;
          height: 100%;

          .AchievementSwiper_SwiperSlide {
            display: flex;
            align-items: center;
            justify-content: center;

            .item {
              width: 281px;
              height: 186px;
              position: relative;
              background: linear-gradient(90deg,
                      #DEE3EE 0%,
                      #E9ECF4 49%,
                      #DEE3EE 100%);
              border: 7px solid #c7cede;
              vertical-align: middle;
              //transition: all 1s linear;
              cursor: pointer;

              &.placeholder {

                opacity: 0;
              }

              .bg {
                position: absolute;
                top: 2px;
                left: 2px;
                width: 263px;
                height: 166px;
                background: url("../../../../assets/images/huawen@2x.png") no-repeat center center / 100% 100%;
                //transition: all 1s linear;
              }

              .bgColor {
                //transition: opacity 1s linear;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg,
                        #2a6ac5 0%,
                        #6795d7 49%,
                        #2a6ac5 100%);
                opacity: 0;
              }

              .itemContent {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;

                .icon {
                  width: 45px;
                  height: 45px;
                  margin: 33px auto 16px;
                  //transition: all 1s linear;
                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                .itemName {
                  height: 70px;
                  width: 255px;
                  font-size: 17px;
                  line-height: 24px;
                  color: #848da2;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin: 0 auto;
                  //transition: all 1s linear;
                }
              }

              &.active {
                width: 392px;
                height: 260px;

                .bgColor {
                  opacity: 1;
                }

                .bg {
                  width: 365px;
                  height: 230px;
                  left: 8px;
                  top: 8px;
                  opacity: .4;
                }

                .itemContent {
                  .icon {
                    width: 60px;
                    height: 60px;
                    margin-top: 47px;
                    margin-bottom: 27px;
                  }

                  .itemName {
                    width: 330px;
                    height: 96px;
                    font-size: 22px;
                    line-height: 34px;
                    color: #ffffff;
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .infoBox {
    margin-top: 50px;

    .arrow {
      width: 29px;
      height: 16px;
      background: url("../../../../assets/images/jian@2x.png") no-repeat center center / 100% 100%;
      margin: 0 auto;
    }

    .box {
      width: 1200px;
      height: 334-15px;
      background-color: #f2f3f5;
      border-radius: 5px;
      display: flex;
      padding: 44px;
      margin-top: -1.5px;

      .img {
        width: 341px;
        height: 233px;
        overflow: hidden;
        margin-right: 45px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .info {
        flex: 1;
        text-align: left;

        .name {
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          font-weight: bold;
        }

        .text {
          width: 724px;
          font-size: 15px;
          line-height: 26px;
          color: #333333;
          margin-top: 30px;
        }
      }
    }
  }
}
