.mobile .border-1px-bottom {
  position: relative;
}

.mobile .border-1px-bottom::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 200%;
  height: 200%;
  z-index: 10;
  border-bottom: 0.026667rem solid #e4e4e4;
  transform-origin: left top;
  transform: scale(0.5);
  pointer-events: none;
  box-sizing: border-box;
}

@media screen and (min-device-pixel-ratio: 3),(-webkit-min-device-pixel-ratio: 3) {
  .mobile .border-1px-bottom::before {
    width: 300%;
    height: 300%;
    transform: scale(0.333);
  }
}

.mobile .content {
  width: 9.2rem;
  margin: 0 auto;
}

.mobile .CustomNavigation {
  height: 1.493333rem;
}

.mobile .CustomNavigation .CustomNavigationBox {
  height: 1.493333rem;
}

.mobile .CustomNavigation .CustomNavigationBox .icon {
  width: 4.266667rem;
  height: 0.906667rem;
}

.mobile .CustomNavigation .CustomNavigationBox .itemIcon {
  width: 0.533333rem;
  height: 0.533333rem;
  background: url("../../assets/mobile/navigation_white@3x.png") no-repeat center center/100% 100%;
}

.mobile .CustomNavigation .CustomNavigationBox .mobileMenus {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: rgba(0,0,0,.5);
  display: none;
}

.mobile .CustomNavigation .CustomNavigationBox .mobileMenus.active {
  display: block;
}

.mobile .CustomNavigation .CustomNavigationBox .mobileMenus .mobileMenusBox {
  margin-left: 3.066667rem;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.mobile .CustomNavigation .CustomNavigationBox .mobileMenus .mobileMenusBox .mobileMenusClose {
  width: 0.533333rem;
  height: 0.533333rem;
  background: url("../../assets/mobile/navigation_close@3x.png") no-repeat center center/0.533333rem 0.533333rem;
  box-sizing: content-box;
  padding: 0.4rem;
  margin-top: 0.053333rem;
  margin-bottom: 0.533333rem;
}

.mobile .CustomNavigation .CustomNavigationBox .mobileMenus .mobileMenusBox .mobileMenusItems {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.mobile .CustomNavigation .CustomNavigationBox .mobileMenus .mobileMenusBox .mobileMenusItems .item {
  height: 0.64rem;
  margin-top: 1.066667rem;
  text-align: center;
  font-size: 0.453333rem;
  line-height: 0.64rem;
  text-decoration: none;
  display: block;
  color: #333;
  font-weight: normal;
}

.mobile .CustomNavigation.active .CustomNavigationBox .itemIcon {
  background: url("../../assets/mobile/navigation_black@3x.png") no-repeat center center/100% 100%;
}

.mobile .homePage .topBannerModular {
  width: 10rem;
  height: 10.533333rem;
  min-width: 10rem;
}

.mobile .homePage .topBannerModular .bgImg .img {
  width: 4.48rem;
  height: 2.666667rem;
  background: url("../../assets/mobile/home_top_img_1.png") no-repeat center center/100% 100%;
}

.mobile .homePage .aboutModular {
  background: url("../../assets/images/ABOUT@2x.png") no-repeat center 0.666667rem/6.48rem 1.386667rem;
  padding-top: 1.306667rem;
}

.mobile .homePage .aboutModular .info {
  width: 8.613333rem;
  height: 4rem;
  font-size: 0.453333rem;
  line-height: 0.8rem;
  margin: 0 auto 0.8rem;
}

.mobile .homePage .aboutModular .numBox {
  background: url("../../assets/mobile/home_bg2@3x.png") no-repeat center center/100% 100%;
}

.mobile .homePage .aboutModular .numBox .numBoxContent {
  position: relative;
  height: 2.4rem;
  justify-content: space-between;
}

.mobile .homePage .aboutModular .numBox .numBoxContent .item .top .txt {
  font-size: 0.613333rem;
  line-height: 0.666667rem;
  font-weight: bold;
}

.mobile .homePage .aboutModular .numBox .numBoxContent .item .top span {
  font-size: 0.32rem;
  line-height: 0.4rem;
  margin-left: 0.08rem;
}

.mobile .homePage .aboutModular .numBox .numBoxContent .item .bottom {
  margin-top: 0.373333rem;
  font-size: 0.346667rem;
  line-height: 0.48rem;
  color: #333;
}

.mobile .homePage .aboutModular .numBox .numBoxContent .item .des {
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  font-size: 0.32rem;
}

.mobile .homePage .aboutModular .numBox .numBoxContent .item:nth-child(1) {
  margin: 0;
  width: auto;
  margin-left: 0.8rem;
}

.mobile .homePage .aboutModular .numBox .numBoxContent .item:nth-child(2) {
  margin: 0;
  width: auto;
  margin-right: 0.8rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item {
  margin-top: 1.066667rem;
  width: 9.2rem;
  height: 6.666667rem;
  background: url("../../assets/images/home_img1@2x.png") no-repeat left top/7.146667rem 4.186667rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item .info {
  top: 3.253333rem;
  right: 0;
  width: 7.146667rem;
  height: 3.413333rem;
  background-color: #fbfbfb;
  box-shadow: 0.133333rem 0.133333rem 0.32rem 0 rgba(27,53,89,.08);
  padding-left: 0.666667rem;
  padding-top: 0.48rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item .info .title {
  font-size: 0.533333rem;
  line-height: 0.746667rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item .info .line {
  margin-top: 0.266667rem;
  width: 0.8rem;
  height: 0.026667rem;
  background: #1c5db9;
}

.mobile .homePage .aboutModular .businessIntroduce .item .info .text {
  margin-top: 0.293333rem;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  width: 6rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item:hover {
  background-position: -0.533333rem 0;
}

.mobile .homePage .aboutModular .businessIntroduce .item:hover .info {
  right: -0.4rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item:nth-child(even) {
  background-position: right top;
}

.mobile .homePage .aboutModular .businessIntroduce .item:nth-child(even) .info {
  right: 2.053333rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item:nth-child(even):hover {
  background-position: 2.586667rem top;
}

.mobile .homePage .aboutModular .businessIntroduce .item:nth-child(even):hover .info {
  right: 2.453333rem;
}

.mobile .homePage .aboutModular .businessIntroduce .item:nth-child(2) {
  background-image: url("../../assets/images/home_img2@2x.png");
}

.mobile .homePage .aboutModular .businessIntroduce .item:nth-child(3) {
  background-image: url("../../assets/images/home_img3@2x.png");
}

.mobile .homePage .empowermentModular {
  background: #0e131c url("../../assets/mobile/home_bg3@3x.png") no-repeat center center/100% 100%;
}

.mobile .homePage .empowermentModular .empowermentContent {
  margin-top: 1.066667rem;
  height: 2.666667rem;
  width: 5.333333rem;
}

.mobile .homePage .empowermentModular .empowermentContent .item .icon {
  width: 0.8rem;
  height: 0.8rem;
}

.mobile .homePage .empowermentModular .empowermentContent .item .text {
  font-size: 0.346667rem;
  margin-top: 0.213333rem;
  line-height: 0.48rem;
}

.mobile .newsComponent {
  padding-top: 0.666667rem;
  padding-bottom: 1.066667rem;
}

.mobile .newsComponent .title .text {
  font-size: 0.693333rem;
  line-height: 0.96rem;
}

.mobile .newsComponent .title .info {
  margin-top: 0;
  font-size: 0.346667rem;
  line-height: 0.533333rem;
}

.mobile .newsComponent .flexContent {
  margin-top: 0.666667rem;
  justify-content: flex-start;
  flex-direction: column;
}

.mobile .newsComponent .flexContent .swiperLeft {
  width: 9.2rem;
}

.mobile .newsComponent .flexContent .swiperLeft .swiperBox {
  width: 9.2rem;
  height: 4.693333rem;
}

.mobile .newsComponent .flexContent .swiperLeft .swiperBox .swiperBoxContent {
  width: 9.2rem;
  height: 4.693333rem;
}

.mobile .newsComponent .flexContent .swiperLeft .swiperBox .swiperBoxContent .swiper-button-prev,
.mobile .newsComponent .flexContent .swiperLeft .swiperBox .swiperBoxContent .swiper-button-next {
  width: 0.373333rem;
  height: 1.066667rem;
  background: #000 url("../../assets/images/left_page@2x.png") no-repeat center center/0.266667rem 0.266667rem;
  margin-top: -0.533333rem;
}

.mobile .newsComponent .flexContent .swiperLeft .swiperBox .swiperBoxContent .swiper-button-prev.swiper-button-next,
.mobile .newsComponent .flexContent .swiperLeft .swiperBox .swiperBoxContent .swiper-button-next.swiper-button-next {
  background: #000 url("../../assets/images/right_page@2x.png") no-repeat center center/0.266667rem 0.266667rem;
}

.mobile .newsComponent .flexContent .swiperLeft .time {
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  margin: 0.666667rem 0 0.24rem;
}

.mobile .newsComponent .flexContent .swiperLeft .title {
  width: 9.2rem;
  font-size: 0.453333rem;
  line-height: 0.693333rem;
  height: 1.386667rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.mobile .newsComponent .flexContent .list {
  width: 9.2rem;
  margin-top: 0.64rem;
}

.mobile .newsComponent .flexContent .list .item {
  height: 1.946667rem;
  margin-bottom: 0.4rem;
  padding-left: 0.4rem;
  width: 9.2rem;
  flex-direction: column;
}

.mobile .newsComponent .flexContent .list .item:before {
  top: 0.266667rem;
  width: 0.16rem;
  height: 0.16rem;
}

.mobile .newsComponent .flexContent .list .item .title {
  width: 8.8rem;
  font-size: 0.426667rem;
  line-height: 0.666667rem;
  height: 1.333333rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  white-space: normal;
  -webkit-box-orient: vertical;
}

.mobile .newsComponent .flexContent .list .item .time {
  width: 100%;
  font-size: 0.4rem;
  line-height: 0.56rem;
  margin-top: 0.053333rem;
  text-align: right;
}

.mobile .newsComponent .moreButton {
  display: block;
  background-color: #ecf3fe;
  border: none;
  font-size: 0.266667rem;
  margin: 0.4rem auto 0;
  color: #1c5db9;
}

.mobile .footerComponent .footerContent {
  height: 7.973333rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.mobile .footerComponent .footerContent .list {
  margin: 1.6rem auto 0;
  width: 8.826667rem;
  display: flex;
  justify-content: center;
}

.mobile .footerComponent .footerContent .list .item {
  margin: 0;
}

.mobile .footerComponent .footerContent .list .item .qr {
  width: 2.4rem;
  height: 2.4rem;
}

.mobile .footerComponent .footerContent .list .item .text {
  font-size: 0.32rem;
  margin-top: 0.4rem;
  line-height: 0.453333rem;
}

.mobile .footerComponent .footerContent .keepOnRecord {
  width: 8.933333rem;
  height: 1.066667rem;
  margin: 0.933333rem auto;
  text-align: center;
}

.mobile .footerComponent .footerContent .keepOnRecord a {
  font-size: 0.346667rem;
  line-height: 0.533333rem;
}

.mobile .TopBanner {
  height: 5.866667rem;
  margin-top: -1.493333rem;
}

.mobile .TopBanner img {
  object-fit: cover;
}

.mobile .TopBanner .bannerContent .titleEnglish {
  margin-top: 0.96rem;
  line-height: 0.693333rem;
  font-size: 0.64rem;
  height: 0.693333rem;
}

.mobile .TopBanner .bannerContent .line {
  width: 0.933333rem;
  height: 0.026667rem;
  margin: 0.266667rem 0;
}

.mobile .TopBanner .bannerContent .title {
  font-size: 0.48rem;
  line-height: 0.533333rem;
}

.mobile .layoutComponent {
  margin-top: 1.493333rem;
}

.mobile .layoutComponent .mobileTabs {
  overflow: hidden;
}

.mobile .layoutComponent .mobileTabs .mobile_tabs {
  width: 10rem;
  height: 1.493333rem;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0 0.133333rem;
  vertical-align: top;
  white-space: nowrap;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.mobile .layoutComponent .mobileTabs .mobile_tabs::-webkit-scrollbar {
  display: none;
}

.mobile .layoutComponent .mobileTabs .mobile_tabs .mobile_tabs_item {
  display: inline-block;
  font-size: 0.426667rem;
  color: #333;
  line-height: 1.493333rem;
  padding: 0 0.4rem;
  vertical-align: top;
  position: relative;
}

.mobile .layoutComponent .mobileTabs .mobile_tabs .mobile_tabs_item.active {
  font-weight: bold;
  color: #1c5db9;
}

.mobile .layoutComponent .mobileTabs .mobile_tabs .mobile_tabs_item.active::after {
  display: block;
  content: "";
  position: absolute;
  width: 60%;
  left: 20%;
  bottom: 0;
  height: 0.053333rem;
  background: #1c5db9;
  border-radius: 0.213333rem;
}

.mobile .CompanyProfileIndexPage {
  padding-bottom: 1.333333rem;
  width: 10rem;
  height: 18.133333rem;
  background: url("../../assets/images/mobile/com_bg_mobile.png") no-repeat bottom right/10rem 7.466667rem;
}

.mobile .CompanyProfileIndexPage .title {
  width: 9.2rem;
  margin-left: 0.4rem;
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  margin-top: 1.066667rem;
}

.mobile .CompanyProfileIndexPage .text {
  width: 9.2rem;
  margin-left: 0.4rem;
  margin-top: 0.8rem;
}

.mobile .CompanyProfileIndexPage .text p {
  text-indent: 0.8rem;
  font-size: 0.4rem;
  line-height: 0.8rem;
}

.mobile .CompanyProfileTeamBuildingPage {
  padding-bottom: 0.8rem;
  padding-top: 0.666667rem;
}

.mobile .CompanyProfileTeamBuildingPage .list {
  width: 9.2rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.mobile .CompanyProfileTeamBuildingPage .list .item {
  cursor: pointer;
  width: 100%;
  margin-bottom: 1.066667rem;
}

.mobile .CompanyProfileTeamBuildingPage .list .item .img {
  width: 9.2rem;
  height: 5.226667rem;
}

.mobile .CompanyProfileTeamBuildingPage .list .item .title {
  font-size: 0.453333rem;
  line-height: 0.533333rem;
  margin: 0.533333rem 0 0.4rem;
}

.mobile .CompanyProfileTeamBuildingPage .list .item .button {
  display: none;
}

.mobile .CompanyProfileTeamBuildingPage .Pagination {
  text-align: center;
}

.mobile .CompanyProfileHonorPage {
  padding-top: 0;
  padding-bottom: 0.72rem;
  width: 9.2rem;
  margin: 0 auto;
}

.mobile .CompanyProfileHonorPage .title {
  font-size: 0.506667rem;
  line-height: 0.533333rem;
  color: #333;
  text-align: center;
  height: 1.866667rem;
  padding-top: 0.933333rem;
  margin-bottom: 0.4rem;
  background: url("../../assets/images/UNION@2x.png") no-repeat center center/4.64rem auto;
  margin-top: 0.426667rem;
}

.mobile .CompanyProfileHonorPage .list {
  margin-top: 0.8rem;
  width: 100%;
  justify-content: space-between;
}

.mobile .CompanyProfileHonorPage .list .item {
  width: 4.506667rem;
  height: 3.173333rem;
  margin: 0 0 0.186667rem;
}

.mobile .CompanyProfileHonorPage .list .item img {
  object-fit: cover;
}

.mobile .CompanyProfileHonorPage .list .item .name {
  line-height: 0.56rem;
  font-size: 0.373333rem;
  padding: 0.4rem;
}

.mobile .SolutionIndexPage .title {
  line-height: 0.8rem;
  font-size: 0.586667rem;
  color: #333;
  margin-top: 1.066667rem;
  text-align: center;
}

.mobile .SolutionIndexPage .headTitle {
  height: 0.4rem;
  font-size: 0.4rem;
  line-height: 0.4rem;
  color: #333;
  margin-top: 0.24rem;
  text-align: center;
}

.mobile .SolutionIndexPage .tip {
  font-size: 0.373333rem;
  line-height: 0.4rem;
  color: #aeaeae;
  text-align: center;
  margin-top: 0.266667rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .des {
  font-size: 0.4rem;
  line-height: 0.613333rem;
  margin-top: 0.426667rem;
  padding: 0 0.4rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity {
  display: block;
  width: 9.2rem;
  height: auto;
  border: none;
  padding: 0.4rem;
  background: #f2f3f5;
  margin: 0.533333rem auto 0;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .left {
  width: 8.4rem;
  height: 4.266667rem;
  background: url("../../assets/mobile/school_img01@3x.png") no-repeat center center/100% 100%;
  padding: 1.44rem 0 0;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .left .title {
  height: auto;
  line-height: 0.746667rem;
  font-size: 0.533333rem;
  text-align: center;
  margin: 0;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .left .info {
  font-size: 0.4rem;
  text-align: center;
  line-height: 0.4rem;
  margin: 0.24rem 0 0;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .top {
  padding: 0;
  height: 0.746667rem;
  margin: 0.4rem 0;
  background: none;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .top.digitalCampusCenterSwiperPagination .swiper-pagination-bullet {
  display: block;
  width: 2.4rem;
  height: 0.746667rem;
  cursor: pointer;
  border-radius: 0.906667rem;
  position: relative;
  padding: 0;
  margin: 0;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .top.digitalCampusCenterSwiperPagination .swiper-pagination-bullet:before {
  font-size: 0.346667rem;
  line-height: 0.746667rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom {
  margin-top: 0.133333rem;
  width: 8.4rem;
  overflow: hidden;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom .digitalCampusCenterSwiper {
  width: 8.4rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom .digitalCampusCenterSwiper .swiper-wrapper {
  width: 8.4rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom .itemContent {
  padding: 0;
  width: 8.4rem;
  justify-content: flex-start;
  flex-flow: wrap;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom .itemContent .item {
  display: inline-block;
  width: 1.92rem;
  height: 2.666667rem;
  padding: 0;
  margin: 0.08rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom .itemContent .item .icon {
  margin-top: 0.506667rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom .itemContent .item .headTitle {
  font-size: 0.346667rem;
}

.mobile .SolutionIndexPage .DigitalCampusCenter .schoolSecurity .right .bottom .itemContent .item .headTitle.small {
  font-size: 0.293333rem;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent {
  width: 10rem;
  height: 8.8rem;
  overflow-x: auto;
  overflow-y: hidden;
  display: block;
  white-space: nowrap;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent::-webkit-scrollbar {
  display: none;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent .item {
  display: inline-block;
  width: 6.4rem;
  height: 8.8rem;
  margin: 0 0.533333rem 0 0;
  padding: 6.346667rem 0.4rem 0.4rem;
  font-size: 0.48rem;
  line-height: 0.666667rem;
  text-align: left;
  color: #333;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent .item:nth-child(1) {
  background: #f2f3f5 url("../../assets/images/school_img02@2x.png") no-repeat 0.4rem 0.4rem/5.6rem 5.6rem;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent .item:nth-child(2) {
  background: #f2f3f5 url("../../assets/images/school_img03@2x.png") no-repeat 0.4rem 0.4rem/5.6rem 5.6rem;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent .item:nth-child(3) {
  background: #f2f3f5 url("../../assets/images/school_img04@2x.png") no-repeat 0.4rem 0.4rem/5.6rem 5.6rem;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent .item .hover {
  display: block;
  opacity: 1;
  background: none;
  width: 5.253333rem;
  height: 1.12rem;
  top: 7.2rem;
  left: 0.4rem;
  margin: 0;
  padding: 0;
}

.mobile .SolutionIndexPage .campusPayment .campusPaymentContent .item .hover p {
  color: #333;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationContent {
  width: 9.2rem;
  height: 8.106667rem;
  background-color: #f2f3f5;
  margin: 0.826667rem auto 0;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper {
  width: 8.4rem;
  height: 6.666667rem;
  margin: 0 auto;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item {
  width: 100%;
  height: 100%;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item img {
  width: 8.4rem;
  height: 4.533333rem;
  object-fit: cover;
  margin-top: 0.4rem;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom {
  margin-top: 0.346667rem;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom h1 {
  font-size: 0.48rem;
  line-height: 0.693333rem;
  color: #333;
  font-weight: bold;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom p {
  font-size: 0.4rem;
  line-height: 0.48rem;
  margin-top: 0.213333rem;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationMobilePagination {
  margin-top: 0.506667rem;
  height: 0.106667rem;
  text-align: center;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationMobilePagination .swiper-pagination-bullet {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 0.533333rem;
  height: 0.106667rem;
  background: #e4e6eb;
}

.mobile .SolutionIndexPage .intelligentCommunicationMobile .intelligentCommunicationMobilePagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #1c5db9;
}

.mobile .SolutionIndexPage .intelligentLogistics .intelligentLogisticsContent {
  margin-top: 0.8rem;
}

.mobile .SolutionIndexPage .intelligentLogistics .intelligentLogisticsContent .item {
  padding: 0.4rem;
  width: 9.2rem;
  height: 2.933333rem;
  background: #f2f3f5 !important;
  margin: 0 auto 0.613333rem;
  display: flex;
  align-items: center;
}

.mobile .SolutionIndexPage .intelligentLogistics .intelligentLogisticsContent .item .icon {
  width: 2.133333rem;
  height: 2.133333rem;
  border-radius: 2.133333rem;
  overflow: hidden;
  margin-right: 0.373333rem;
}

.mobile .SolutionIndexPage .intelligentLogistics .intelligentLogisticsContent .item .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile .SolutionIndexPage .intelligentLogistics .intelligentLogisticsContent .item .contentTitle {
  line-height: 0.666667rem;
  font-size: 0.48rem;
}

.mobile .SolutionIndexPage .intelligentLogistics .intelligentLogisticsContent .item .text {
  width: 5.866667rem;
  font-size: 0.4rem;
  line-height: 0.613333rem;
}

.mobile .SolutionIndexPage .CustomerCase {
  background: none;
  width: 10rem;
  margin-top: 0;
  margin-bottom: 0;
  height: 8rem;
}

.mobile .SolutionIndexPage .CustomerCase .customerCaseTitle {
  color: #333;
}

.mobile .SolutionIndexPage .CustomerCase .customerCaseContent .ani {
  height: 2.8rem;
}

.mobile .SolutionIndexPage .CustomerCase .customerCaseContent .WalkingLantern .aniContent .item {
  width: 2.826667rem;
  height: 2.4rem;
  box-shadow: 0 0 0.106667rem 0.053333rem #f2f2f2;
  margin-top: 0.32rem;
  margin-left: 0.373333rem;
}

.mobile .EducationalInnovationPage {
  min-height: auto;
}

.mobile .EducationalInnovationPage .headTitle {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  letter-spacing: 0.026667rem;
  margin-top: 1.066667rem;
  color: #000;
}

.mobile .EducationalInnovationPage .headInfo {
  font-size: 0.4rem;
  line-height: 0.613333rem;
  width: 9.2rem;
  margin: 0.426667rem auto 0;
  text-align: left;
}

.mobile .EducationalInnovationPage .qylLibrary {
  background: none;
  height: auto;
  width: 9.2rem;
  margin: 0 auto;
}

.mobile .EducationalInnovationPage .qylLibrary .head {
  width: 9.2rem;
  height: 4.266667rem;
  background: url("../../assets/mobile/edu_bg@3x.png") no-repeat center center/100% 100%;
  padding-top: 1.333333rem;
}

.mobile .EducationalInnovationPage .qylLibrary .head .title {
  font-size: 0.533333rem;
  line-height: 0.746667rem;
}

.mobile .EducationalInnovationPage .qylLibrary .head .info {
  font-size: 0.4rem;
  line-height: 0.4rem;
}

.mobile .EducationalInnovationPage .qylLibrary .qylLibraryContent {
  padding: 0;
  display: block;
  margin: 0.533333rem 0 0;
}

.mobile .EducationalInnovationPage .qylLibrary .qylLibraryContent .item {
  width: 9.2rem;
  height: 2.933333rem;
  background-color: #f2f3f5;
  border-left: 0.08rem solid #1c5db9;
  padding-left: 0.666667rem;
  padding-top: 0.346667rem;
  margin-right: 0.506667rem;
  margin-bottom: 0.666667rem;
}

.mobile .EducationalInnovationPage .qylLibrary .qylLibraryContent .item .top {
  height: 0.8rem;
}

.mobile .EducationalInnovationPage .qylLibrary .qylLibraryContent .item .top .icon {
  width: 0.8rem;
  height: 0.8rem;
}

.mobile .EducationalInnovationPage .qylLibrary .qylLibraryContent .item .top .inlineTitle {
  font-size: 0.48rem;
  line-height: 0.693333rem;
  margin-left: 0.32rem;
}

.mobile .EducationalInnovationPage .qylLibrary .qylLibraryContent .item .text {
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #333;
  margin-top: 0.266667rem;
  width: 8.266667rem;
}

.mobile .EducationalInnovationPage .famousTeacherResources {
  display: block;
  background: none;
  width: 9.2rem;
  margin: 1.066667rem auto 0;
  padding: 0;
  height: auto;
}

.mobile .EducationalInnovationPage .famousTeacherResources .left {
  padding: 0;
  width: auto;
}

.mobile .EducationalInnovationPage .famousTeacherResources .left .title {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
}

.mobile .EducationalInnovationPage .famousTeacherResources .left .info {
  font-size: 0.4rem;
  line-height: 0.4rem;
  margin-top: 0.4rem;
  text-align: center;
}

.mobile .EducationalInnovationPage .famousTeacherResources .left .text {
  width: 100%;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  margin: 0;
  padding: 0;
}

.mobile .EducationalInnovationPage .famousTeacherResources .left img {
  width: 7.546667rem;
  height: auto;
  margin: 0.906667rem auto 0.4rem;
  display: block;
}

.mobile .EducationalInnovationPage .famousTeacherResources .right {
  display: none;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile {
  padding-bottom: 1.066667rem;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .title {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  font-weight: bold;
  margin-top: 1.066667rem;
  color: #000;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .headTitle {
  width: 9.2rem;
  margin: 0.4rem auto 0;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  text-align: left;
  color: #333;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationContent {
  width: 9.2rem;
  height: 8.666667rem;
  background-color: #f2f3f5;
  margin: 0.826667rem auto 0;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper {
  width: 8.4rem;
  margin: 0 auto;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item {
  width: 100%;
  height: 100%;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item img {
  width: 8.4rem;
  height: 4.533333rem;
  object-fit: cover;
  margin-top: 0.4rem;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom {
  margin-top: 0.346667rem;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom h1 {
  font-size: 0.48rem;
  line-height: 0.693333rem;
  color: #333;
  font-weight: bold;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom p {
  font-size: 0.4rem;
  line-height: 0.613333rem;
  margin-top: 0.106667rem;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationMobilePagination {
  margin-top: 0.506667rem;
  height: 0.106667rem;
  text-align: center;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationMobilePagination .swiper-pagination-bullet {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 0.533333rem;
  height: 0.106667rem;
  background: #e4e6eb;
}

.mobile .EducationalInnovationPage .intelligentCompositionCorrectionMobile .intelligentCommunicationMobilePagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #1c5db9;
}

.mobile .K12ServiceCentrePage .headTitle {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 1.066667rem;
}

.mobile .K12ServiceCentrePage .headInfo {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: left;
}

.mobile .K12ServiceCentrePage .tip {
  font-size: 0.373333rem;
  line-height: 0.4rem;
  color: #aeaeae;
  text-align: center;
  margin-top: 0.266667rem;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass {
  width: 9.2rem;
  padding: 0;
  height: auto;
  display: block;
  background: none;
  margin: 0.32rem auto 0;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .head {
  width: 9.2rem;
  height: 4.266667rem;
  background: url("../../assets/mobile/k12_bg1@3x.png") no-repeat center center/100% 100%;
  padding-top: 1.333333rem;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .head .title {
  font-size: 0.533333rem;
  line-height: 0.746667rem;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .head .info {
  font-size: 0.4rem;
  line-height: 0.4rem;
  margin-top: 0.32rem;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent {
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  justify-content: space-between;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item {
  width: 4.4rem;
  height: 5.866667rem;
  background-color: #f2f3f5;
  padding: 0;
  margin: 0.533333rem 0 0;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .itemContent,
.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .hover {
  display: block;
  position: relative;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .itemContent.itemContent .icon,
.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .hover.itemContent .icon {
  width: 0.853333rem;
  height: 0.853333rem;
  margin: 0.64rem auto 0.373333rem;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .itemContent.itemContent .text,
.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .hover.itemContent .text {
  font-size: 0.48rem;
  line-height: 0.666667rem;
}

.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .itemContent.hover,
.mobile .K12ServiceCentrePage .delayManagementAfterClass .delayManagementAfterClassContent .item .hover.hover {
  display: block;
  background: none;
  padding: 0;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #333;
  width: 3.653333rem;
  margin: 0.4rem auto 0;
  opacity: 1;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .title {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 1.066667rem;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .info {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: center;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .campusCateringSystemContent {
  margin-top: 0.8rem;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .campusCateringSystemContent .item {
  width: 9.2rem;
  height: 3.493333rem;
  background-color: #f2f3f5;
  margin: 0 auto 0.4rem;
  align-items: flex-start;
  padding-top: 0.4rem;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .campusCateringSystemContent .item .icon {
  width: 1.386667rem;
  height: 1.6rem;
  margin-left: 0.4rem;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .campusCateringSystemContent .item .box {
  height: 3.493333rem;
  width: 6.613333rem;
  margin-left: 0.4rem;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .campusCateringSystemContent .item .box .inlineTitle {
  font-size: 0.48rem;
  line-height: 0.666667rem;
}

.mobile .K12ServiceCentrePage .campusCateringSystem .campusCateringSystemContent .item .box .text {
  font-size: 0.4rem;
  color: #333;
  margin-top: 0.24rem;
  line-height: 0.613333rem;
  width: 6.613333rem;
}

.mobile .K12ServiceCentrePage .cloudParents .banner {
  margin: 0;
  padding: 0;
  height: auto;
  background: none;
}

.mobile .K12ServiceCentrePage .cloudParents .banner .title {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 0.666667rem;
}

.mobile .K12ServiceCentrePage .cloudParents .banner .info {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: center;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent {
  display: block;
  margin-top: 0.8rem;
  width: 10rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 0.4rem;
  height: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent::-webkit-scrollbar {
  display: none;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent .item {
  width: 6.4rem;
  height: 7.066667rem;
  background-color: #f2f3f5;
  display: inline-block;
  margin-right: 0.4rem;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent .item .icon {
  width: 1.573333rem;
  height: 1.84rem;
  margin: 0.746667rem auto 0;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent .item .text {
  font-size: 0.48rem;
  line-height: 0.666667rem;
  margin-top: 0.4rem;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent .item .hover {
  opacity: 1;
  margin-top: 1.226667rem;
  height: 2.666667rem;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent .item .hover .textBox {
  background: #1c5db9;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #fff;
  height: 2.133333rem;
  margin: 0;
  white-space: normal;
  padding: 0.373333rem 0.533333rem;
  text-align: left;
}

.mobile .K12ServiceCentrePage .cloudParents .cloudParentsContent .item .hover:before {
  display: block;
  content: "";
  position: absolute;
  left: 50%;
  top: -0.48rem;
  width: 21.333333rem;
  height: 21.333333rem;
  background-color: #1c5db9;
  border-radius: 10.666667rem;
  margin-left: -10.666667rem;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile {
  margin-bottom: 1.066667rem;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .title {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  font-weight: bold;
  margin-top: 1.066667rem;
  color: #000;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .headTitle {
  width: 9.2rem;
  margin: 0.4rem auto 0;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  text-align: center;
  color: #333;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationContent {
  width: 9.2rem;
  height: 8.666667rem;
  background-color: #f2f3f5;
  margin: 0.826667rem auto 0;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper {
  width: 8.4rem;
  margin: 0 auto;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item {
  width: 100%;
  height: 100%;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item img {
  width: 8.4rem;
  height: 4.533333rem;
  object-fit: cover;
  margin-top: 0.4rem;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom {
  margin-top: 0.346667rem;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom h1 {
  font-size: 0.48rem;
  line-height: 0.693333rem;
  color: #333;
  font-weight: bold;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationContent .intelligentCommunicationMobileSwiper .item .bottom p {
  font-size: 0.4rem;
  line-height: 0.613333rem;
  margin-top: 0.106667rem;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationMobilePagination {
  margin-top: 0.506667rem;
  height: 0.106667rem;
  text-align: center;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationMobilePagination .swiper-pagination-bullet {
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  width: 0.533333rem;
  height: 0.106667rem;
  background: #e4e6eb;
}

.mobile .K12ServiceCentrePage .qualityDevelopmentServiceMobile .intelligentCommunicationMobilePagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #1c5db9;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment {
  display: block;
  height: auto;
  width: 9.2rem;
  margin: 0 auto;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .title {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 1.066667rem;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .info {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: center;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent {
  width: 9.2rem;
  height: auto;
  flex-wrap: wrap;
  margin: 0.8rem auto 0;
  padding: 0;
  display: block;
  background: #fff;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent .icon {
  display: none;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent .list {
  margin: 0;
  padding: 0;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent .list .item {
  width: 4.4rem;
  height: 6.346667rem;
  background-color: #f2f3f5;
  margin: 0 0 0.4rem 0;
  padding: 0;
  display: block;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent .list .item .icon {
  display: block;
  width: 0.933333rem;
  height: 1.066667rem;
  margin: 0.533333rem auto 0;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent .list .item .box {
  margin: 0;
  padding-top: 0.266667rem;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent .list .item .box .inlineTitle {
  font-size: 0.48rem;
  line-height: 0.666667rem;
  text-align: center;
}

.mobile .K12ServiceCentrePage .readingAbilityAssessment .readingAbilityAssessmentContent .list .item .box .inlineInfo {
  width: 3.76rem;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #333;
  margin: 0.4rem auto 0;
  white-space: normal;
  padding: 0;
}

.mobile .BigDataPage {
  padding-bottom: 0.8rem;
}

.mobile .BigDataPage .headTitle {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 1.066667rem;
}

.mobile .BigDataPage .headInfo {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: left;
}

.mobile .BigDataPage .safeRide {
  width: 9.2rem;
  height: 5.013333rem;
  background: url("../../assets/mobile/data_bg@3x.png") no-repeat center center/100% 100%;
  position: relative;
  margin: 0.533333rem auto 0;
}

.mobile .BigDataPage .safeRide .safeRideContent {
  position: absolute;
  left: 3.52rem;
  top: 2.826667rem;
  width: 4.853333rem;
  color: #fff;
}

.mobile .BigDataPage .safeRide .safeRideContent .inlineTitle {
  font-size: 0.533333rem;
  line-height: 0.693333rem;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.mobile .BigDataPage .safeRide .safeRideContent .inlineTitle span {
  font-size: 0.4rem;
  line-height: 0.4rem;
  display: none;
}

.mobile .BigDataPage .safeRide .safeRideContent .line {
  width: 4.533333rem;
  height: 0.026667rem;
  margin-top: 0.16rem;
  margin-bottom: 0.133333rem;
}

.mobile .BigDataPage .safeRide .safeRideContent .text {
  font-size: 0.346667rem;
  line-height: 0.4rem;
}

.mobile .BigDataPage .DigitalFinancePageContentMobile {
  width: 9.2rem;
  margin: 0.133333rem auto 0;
}

.mobile .BigDataPage .DigitalFinancePageContentMobile .item {
  margin-top: 0.4rem;
  background: #f2f3f5;
}

.mobile .BigDataPage .DigitalFinancePageContentMobile .item img {
  width: 9.2rem;
  height: 3.546667rem;
}

.mobile .BigDataPage .DigitalFinancePageContentMobile .item .title {
  line-height: 0.746667rem;
  font-size: 0.533333rem;
  color: #333;
  margin-top: 0.533333rem;
  margin-left: 0.4rem;
  font-weight: bold;
}

.mobile .BigDataPage .DigitalFinancePageContentMobile .item .info {
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #333;
  padding: 0.266667rem 0.4rem 0.533333rem 0.4rem;
  width: 100%;
}

.mobile .BigDataPage .safeRideMobile {
  width: 9.2rem;
  height: 3.626667rem;
  background-color: #1c78b9;
  margin: 0 auto;
  padding: 0.533333rem 0.4rem 0;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  letter-spacing: 0;
  color: #fff;
}

.mobile .DigitalMedicinePage .headTitle {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 1.066667rem;
}

.mobile .DigitalMedicinePage .headInfo {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: left;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent {
  width: 9.2rem;
  margin: 0 auto;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item {
  width: 9.2rem;
  height: auto;
  margin-top: 0.533333rem;
  background: #f2f3f5 !important;
  padding-bottom: 0.666667rem;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .itemContent,
.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .hover {
  width: 9.2rem;
  height: 6.08rem;
  position: relative;
  left: 0;
  top: 0;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .itemContent.hover,
.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .hover.hover {
  opacity: 1;
  width: auto;
  height: auto;
  background: none;
  padding: 0 0.4rem;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .itemContent.hover .title,
.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .hover.hover .title {
  line-height: 0.746667rem;
  margin-top: 0.533333rem;
  font-size: 0.533333rem;
  color: #333;
  text-align: left;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .itemContent.hover .info,
.mobile .DigitalMedicinePage .digitalMedicinePageContent .item .hover.hover .info {
  width: auto;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #333;
  text-align: left;
  font-weight: normal;
  margin: 0.266667rem 0 0;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item:nth-child(1) .itemContent {
  background: url("../../assets/mobile/medical_img1@3x.png") no-repeat center center/100% 100%;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item:nth-child(2) .itemContent {
  background: url("../../assets/mobile/medical_img2@3x.png") no-repeat center center/100% 100%;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item:nth-child(3) .itemContent {
  background: url("../../assets/mobile/medical_img3@3x.png") no-repeat center center/100% 100%;
}

.mobile .DigitalMedicinePage .digitalMedicinePageContent .item:nth-child(4) .itemContent {
  background: url("../../assets/mobile/medical_img4@3x.png") no-repeat center center/100% 100%;
}

.mobile .DigitalFinancePage {
  padding-bottom: 0.8rem;
}

.mobile .DigitalFinancePage .headTitle {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 1.066667rem;
}

.mobile .DigitalFinancePage .headInfo {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: left;
}

.mobile .DigitalFinancePage .DigitalFinancePageContentMobile {
  width: 9.2rem;
  margin: 0.133333rem auto 0;
}

.mobile .DigitalFinancePage .DigitalFinancePageContentMobile .item {
  margin-top: 0.4rem;
  background: #f2f3f5;
}

.mobile .DigitalFinancePage .DigitalFinancePageContentMobile .item img {
  width: 9.2rem;
  height: 3.546667rem;
}

.mobile .DigitalFinancePage .DigitalFinancePageContentMobile .item .title {
  line-height: 0.746667rem;
  font-size: 0.533333rem;
  color: #333;
  margin-top: 0.533333rem;
  margin-left: 0.4rem;
  font-weight: bold;
}

.mobile .DigitalFinancePage .DigitalFinancePageContentMobile .item .info {
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #333;
  padding: 0.266667rem 0.4rem 0.533333rem 0.4rem;
  width: 100%;
}

.mobile .PublicWelfarePage {
  padding-bottom: 0.8rem;
}

.mobile .PublicWelfarePage .headTitle {
  font-size: 0.586667rem;
  line-height: 0.586667rem;
  text-align: center;
  color: #000;
  margin-top: 1.066667rem;
}

.mobile .PublicWelfarePage .headInfo {
  font-size: 0.4rem;
  width: 9.2rem;
  margin: 0.4rem auto 0;
  line-height: 0.613333rem;
  color: #333;
  text-align: left;
}

.mobile .PublicWelfarePage .PublicWelfarePageContent {
  width: 9.2rem;
  margin: 0.8rem auto 0;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile .PublicWelfarePage .PublicWelfarePageContent .item {
  width: 4.533333rem;
  height: 5.28rem;
  margin-bottom: 0.133333rem;
}

.mobile .PublicWelfarePage .PublicWelfarePageContent .item .icon {
  width: 1.36rem;
  height: 1.36rem;
  margin: 1.333333rem auto 0;
}

.mobile .PublicWelfarePage .PublicWelfarePageContent .item .text {
  font-size: 0.453333rem;
  line-height: 0.666667rem;
  margin-top: 0.533333rem;
}

.mobile .CooperationPage {
  width: 10rem;
  margin: 0;
  padding: 1.493333rem 0 0;
}

.mobile .CooperationPage .content {
  margin: 0;
  width: 10rem;
}

.mobile .CooperationPage .TopBanner .content {
  margin: 0 auto;
  width: 9.2rem;
}

.mobile .CooperationPage .topCooperation .title {
  font-size: 0.453333rem;
  line-height: 0.533333rem;
  color: #333;
  text-align: center;
  height: 1.866667rem;
  padding-top: 0.933333rem;
  margin-bottom: 0.4rem;
  background: url("../../assets/images/UNION@2x.png") no-repeat center center/4.64rem auto;
  margin-top: 0.426667rem;
}

.mobile .CooperationPage .CooperativePartner {
  margin: 0;
  padding: 0;
  background: #fff;
}

.mobile .CooperationPage .CooperativePartner .headTitle {
  font-size: 0.586667rem;
  line-height: 0.8rem;
  margin-top: 0.8rem;
  margin-bottom: 0.533333rem;
}

.mobile .CooperationPage .CooperativePartner .list {
  background: #fbfbfb;
  padding: 0.8rem 0.4rem;
  margin: 0;
  justify-content: space-between;
}

.mobile .CooperationPage .CooperativePartner .list .item {
  width: 4.48rem;
  height: 1.333333rem;
  padding: 0;
  margin-bottom: 0.266667rem;
  margin-right: 0;
}

.mobile .SocialWelfarePage {
  display: flex;
  flex-direction: column;
}

.mobile .SocialWelfarePage .topBannerModular {
  height: 10.533333rem;
  min-width: 10rem;
  position: relative;
}

.mobile .SocialWelfarePage .topBannerModular .video_bg {
  display: flex;
  width: 100%;
  height: 10.533333rem;
  object-fit: cover;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

.mobile .SocialWelfarePage .topBannerModular .video_mask {
  display: flex;
  position: absolute;
  width: 100%;
  height: 10.533333rem;
}

.mobile .SocialWelfarePage .topBannerModular .bgImg {
  position: absolute;
  color: #fff;
  text-shadow: 0.026667rem 0.026667rem 0.053333rem #666;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
  width: 90%;
}

.mobile .SocialWelfarePage .topBannerModular .bgImg ._title {
  font-size: 0.64rem;
  font-weight: 400;
  margin-bottom: .3125rem;
  transition: opacity .5s ease;
}

.mobile .SocialWelfarePage .topBannerModular .bgImg ._des {
  font-size: 0.426667rem;
  transition: opacity .5s ease;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile {
  padding: 0.96rem 0.4rem;
  background-image: url("../../assets/mobile/social_welfare_mobile_bg.png");
  background-position: center center;
  background-size: 100% 100%;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .swiper {
  height: 5.2rem;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .swiper .swiper-button-prev,
.mobile .SocialWelfarePage .PublicWelfareColumnMobile .swiper .swiper-button-next {
  width: 0.5376rem;
  height: 1.536rem;
  margin-top: -0.768rem;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile:nth-child(2n) {
  background: #fff;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .infoBox {
  margin-top: 1.066667rem;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .infoBox .title span {
  display: block;
  text-align: center;
  font-family: PingFangSC-Semibold;
  font-size: 0.586667rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .infoBox .title small {
  display: block;
  font-size: 0.373333rem;
  font-family: PingFangSC-Medium;
  font-size: 0.426667rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #005dc0;
  margin-top: 0.266667rem;
  text-align: center;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .infoBox .info {
  font-family: PingFangSC-Regular;
  font-size: 0.4rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.8rem;
  letter-spacing: 0;
  color: #333;
  margin-top: 0.4rem;
  white-space: pre-wrap;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .video {
  position: relative;
  height: 4.96rem;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .video .bg {
  position: absolute;
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile .SocialWelfarePage .PublicWelfareColumnMobile .video .videoContent {
  position: absolute;
  width: 8.8rem;
  height: 5.066667rem;
  border: 0.133333rem solid #fff;
  left: 50%;
  top: 0;
  margin: 0 auto 0 -4.4rem;
  box-shadow: 0 0 0.106667rem 0.106667rem #f2f7fb;
}

.mobile .SocialWelfarePage .statistics {
  height: auto;
}

.mobile .SocialWelfarePage .statistics .content {
  width: 10rem;
  flex-wrap: wrap;
}

.mobile .SocialWelfarePage .statistics .content .item {
  width: 4.986667rem;
  height: 2.506667rem;
}

.mobile .SocialWelfarePage .statistics .content .item:nth-child(2n)::after {
  content: none;
}

.mobile .SocialWelfarePage .statistics .content .item .number {
  align-items: center;
}

.mobile .SocialWelfarePage .statistics .content .item .number .count {
  font-family: PingFangSC-Semibold;
  font-size: 0.613333rem;
  font-weight: bold;
  font-stretch: normal;
  line-height: 0.666667rem;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .statistics .content .item .number .suffix {
  font-family: PingFangSC-Regular;
  font-size: 0.48rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.48rem;
  letter-spacing: 0;
  color: #333;
  margin-left: 0.08rem;
}

.mobile .SocialWelfarePage .statistics .content .item .label {
  font-family: PingFangSC-Regular;
  font-size: 0.346667rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
  margin-top: 0.373333rem;
}

.mobile .SocialWelfarePage .projects {
  padding-top: 0.8rem;
}

.mobile .SocialWelfarePage .projects .layer-title {
  width: 10rem;
  font-family: PingFangSC-Semibold;
  font-size: 0.586667rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .projects .adapted_box {
  width: 10rem;
  padding: 0.533333rem 0.4rem;
  margin: 0;
}

.mobile .SocialWelfarePage .projects .adapted_box .item {
  width: 100%;
  height: auto;
  margin-bottom: 0.533333rem;
  box-shadow: none;
}

.mobile .SocialWelfarePage .projects .adapted_box .item:last-child {
  margin-bottom: 0;
}

.mobile .SocialWelfarePage .projects .adapted_box .item .img {
  height: 6.08rem;
}

.mobile .SocialWelfarePage .projects .adapted_box .item .info {
  padding: 0.533333rem 0.4rem;
  position: unset;
  max-height: 26.666667rem;
  background-color: #f2f3f5;
}

.mobile .SocialWelfarePage .projects .adapted_box .item .info .title {
  font-family: PingFangSC-Semibold;
  font-size: 0.48rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .projects .adapted_box .item .info .desc {
  -webkit-line-clamp: 100;
  margin-top: 0.373333rem;
  font-family: PingFangSC-Regular;
  font-size: 0.4rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.666667rem;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .expertTeam {
  padding: 0.266667rem 0 0 0;
}

.mobile .SocialWelfarePage .expertTeam .layer-title {
  width: 10rem;
}

.mobile .SocialWelfarePage .expertTeam .layer-title .title {
  font-family: PingFangHK-Semibold;
  font-size: 0.48rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .expertTeam .cont {
  width: 10rem;
  margin-top: 0.533333rem;
  padding: 0 0.4rem;
}

.mobile .SocialWelfarePage .expertTeam .cont .item {
  width: 100%;
  height: auto;
  margin-bottom: 0.4rem;
  padding: 0.533333rem 0.4rem;
}

.mobile .SocialWelfarePage .expertTeam .cont .item:last-child {
  margin-bottom: 0;
}

.mobile .SocialWelfarePage .expertTeam .cont .item .left {
  width: 2.8rem;
}

.mobile .SocialWelfarePage .expertTeam .cont .item .left img {
  width: 2.8rem;
  height: 3.733333rem;
  left: 0;
  top: 0;
}

.mobile .SocialWelfarePage .expertTeam .cont .item .left::after {
  content: "";
  width: 0.64rem;
  height: 0.666667rem;
  background-color: #f2f3f5;
  border: solid 0.08rem #1c5db9;
  top: 3.253333rem;
  right: -0.186667rem;
}

.mobile .SocialWelfarePage .expertTeam .cont .item .right {
  padding: 0 0 0 0.533333rem;
}

.mobile .SocialWelfarePage .expertTeam .cont .item .right .name {
  font-family: PingFangSC-Medium;
  font-size: 0.426667rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #1c5db9;
}

.mobile .SocialWelfarePage .expertTeam .cont .item .right .position {
  margin-top: 0.266667rem;
  font-family: PingFangSC-Regular;
  font-size: 0.373333rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.586667rem;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .memorabilia {
  background-image: none;
  padding-top: 0.8rem;
}

.mobile .SocialWelfarePage .memorabilia .content {
  width: 10rem;
  padding: 0;
}

.mobile .SocialWelfarePage .memorabilia .content .layer-title {
  padding: 0;
  font-family: PingFangHK-Semibold;
  font-size: 0.586667rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap {
  margin-top: 0.533333rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box {
  width: 2.133333rem;
  height: 16rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line {
  width: 0.053333rem;
  height: 16rem;
  margin-left: 2.106667rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .row-box {
  width: 2.133333rem;
  left: -2.133333rem;
  height: height;
  text-indent: 0.4rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .row-box::before {
  width: 0.24rem;
  height: 0.24rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .row-box.active .year {
  font-size: 0.48rem;
  color: #1c5db9;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .row-box.active::before {
  width: 0.346667rem;
  height: 0.346667rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .row-box .year {
  font-family: PingFangHK-Semibold;
  font-size: 0.426667rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .position1 {
  top: 0.613333rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .position2 {
  top: 4.586667rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .position3 {
  top: 8.56rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .left-box .column-line .position4 {
  top: 12.506667rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content {
  padding: 0.533333rem 0.4rem 0.4rem 0.533333rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content .content-title {
  width: 2.4rem;
  height: 0.96rem;
  background-color: #f4f5f7;
  border-radius: 0.48rem;
  font-family: PingFangHK-Semibold;
  font-size: 0.48rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
  line-height: 0.96rem;
  text-align: center;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content .content-list {
  margin-top: 0.373333rem;
  max-height: 13.866667rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content .content-list.expand {
  max-height: 80rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content .content-list .list-row {
  font-family: PingFangSC-Regular;
  font-size: 0.4rem;
  font-weight: normal;
  font-stretch: normal;
  line-height: 0.666667rem;
  letter-spacing: 0;
  color: #333;
  padding-left: 0.48rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content .content-list .list-row::after {
  width: 0.106667rem;
  height: 0.106667rem;
  top: 0.266667rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content .content-list .list-row:not(:last-child) {
  margin-bottom: 0.373333rem;
}

.mobile .SocialWelfarePage .memorabilia .content .content-wrap .right-box .right-content .load-more {
  padding: 0.8rem 0 0 0.693333rem;
  font-size: 0.373333rem;
}

.mobile .SocialWelfarePage .linkTab {
  padding-top: 0.8rem;
}

.mobile .SocialWelfarePage .linkTab .content {
  width: 10rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .tabs {
  height: 1.066667rem;
  padding: 0;
  font-size: 0.373333rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .tabs .tab-item {
  flex: none;
  padding: 0 0.213333rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .tabs .tab-item.active {
  font-family: PingFangSC-Semibold;
  font-size: 0.373333rem;
  font-weight: bold;
  color: #1c5db9;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .tabs .tab-item.active::after {
  width: 1.173333rem;
  height: 0.053333rem;
  bottom: 0;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-school-content {
  padding: 0.533333rem 0.4rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-school-content .tab-item {
  width: 4.4rem;
  height: 1.333333rem;
  flex-direction: row;
  background-color: #fff;
  border: 0.026667rem solid #ebebeb;
  margin: 0 0.4rem 0.4rem 0;
  padding: 0.133333rem 0.266667rem;
  align-items: center;
  justify-content: flex-start;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-school-content .tab-item:nth-child(2n) {
  margin-right: 0;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-school-content .tab-item .logo {
  width: 1.066667rem;
  height: 1.066667rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-school-content .tab-item .logo img {
  width: 1.066667rem;
  height: 1.066667rem;
  object-fit: cover;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-school-content .tab-item .name {
  font-family: PingFangSC-Regular;
  font-size: 0.32rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
  text-align: left;
  margin-top: 0;
  height: auto;
  margin-left: 0.106667rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-partner-content {
  padding: 0.533333rem 0.4rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-partner-content .tab-item {
  width: 4.4rem;
  height: 1.333333rem;
  margin: 0 0.4rem 0.4rem 0;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-partner-content .tab-item:nth-child(2n) {
  margin-right: 0;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-partner-content .tab-item img {
  width: 4.4rem;
  height: 1.333333rem;
  object-fit: cover;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-donated-content {
  padding: 0;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-donated-content .column {
  width: 10rem;
  margin-right: 0;
  background-color: #f4f5f7;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-donated-content .column .column-card {
  margin-bottom: 0.266667rem;
  background-color: #fff;
  padding: 0 0.4rem;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-donated-content .column .column-card .title-box {
  font-family: PingFangSC-Semibold;
  font-size: 0.453333rem;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
  text-align: left;
  height: 1.6rem;
  background-color: #fff;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-donated-content .column .column-card .card-content {
  border: none;
  padding: 0 0 0.4rem 0;
  font-family: PingFangSC-Regular;
  font-size: 0.4rem;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0;
  color: #333;
}

.mobile .SocialWelfarePage .linkTab .content .custom-tab .container .tab-donated-content .column .column-card .card-content div:not(:last-child) {
  margin-bottom: 0.4rem;
}

.mobile .JoinUsHomePage {
  padding-top: 1.493333rem;
  padding-bottom: 0.8rem;
}

.mobile .JoinUsHomePage .buttonBox {
  display: block;
  margin: 0.4rem auto 0;
  width: 9.2rem;
}

.mobile .JoinUsHomePage .buttonBox .item {
  width: 9.2rem;
  height: 3.92rem;
  display: block;
  margin-top: 0.4rem;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent,
.mobile .JoinUsHomePage .buttonBox .item .bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent .title,
.mobile .JoinUsHomePage .buttonBox .item .bg .title {
  line-height: 0.746667rem;
  font-size: 0.533333rem;
  color: #333;
  text-align: left;
  margin-left: 0.533333rem;
  margin-top: 1.333333rem;
  width: auto;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent .info,
.mobile .JoinUsHomePage .buttonBox .item .bg .info {
  margin-top: 0.133333rem;
  margin-left: 0.56rem;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent .info .text,
.mobile .JoinUsHomePage .buttonBox .item .bg .info .text {
  font-size: 0.266667rem;
  letter-spacing: .1.386667rem;
  color: #aeaeae;
  line-height: 0.373333rem;
  transition: none;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent .info .icon,
.mobile .JoinUsHomePage .buttonBox .item .bg .info .icon {
  width: 0.426667rem;
  height: 0.426667rem;
  margin-left: 0.16rem;
  transition: none;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent.buttonBoxContent,
.mobile .JoinUsHomePage .buttonBox .item .bg.buttonBoxContent {
  width: 3.866667rem;
  height: 3.92rem;
  right: 0;
  bottom: 0;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent.bg,
.mobile .JoinUsHomePage .buttonBox .item .bg.bg {
  left: 0;
  top: 0;
  width: 5.333333rem;
  height: 3.92rem;
  transition: none;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent.buttonBoxContent,
.mobile .JoinUsHomePage .buttonBox .item .bg.buttonBoxContent {
  width: 3.866667rem;
  height: 3.92rem;
  right: 0;
  bottom: 0;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent.buttonBoxContent:hover .info .text,
.mobile .JoinUsHomePage .buttonBox .item .bg.buttonBoxContent:hover .info .text {
  color: #aeaeae;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent.buttonBoxContent:hover .info .icon,
.mobile .JoinUsHomePage .buttonBox .item .bg.buttonBoxContent:hover .info .icon {
  margin-left: 0.16rem;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent.bg,
.mobile .JoinUsHomePage .buttonBox .item .bg.bg {
  width: 5.333333rem;
  height: 3.92rem;
}

.mobile .JoinUsHomePage .buttonBox .item .buttonBoxContent.bg:hover,
.mobile .JoinUsHomePage .buttonBox .item .bg.bg:hover {
  width: 5.333333rem;
  height: 3.92rem;
}

.mobile .JoinUsHomePage .workEnvironment .title {
  width: auto;
  line-height: 0.8rem;
  font-size: 0.586667rem;
  color: #333;
  margin: 0.8rem auto;
}

.mobile .JoinUsHomePage .workEnvironment .workEnvironmentContent {
  width: 9.12rem;
  height: 4.266667rem;
  margin: 0 auto;
  display: block;
}

.mobile .JoinUsHomePage .workEnvironment .workEnvironmentContent .workEnvironmentNavigation,
.mobile .JoinUsHomePage .workEnvironment .workEnvironmentContent .workEnvironmentPrev,
.mobile .JoinUsHomePage .workEnvironment .workEnvironmentContent .workEnvironmentNext {
  display: none;
}

.mobile .JoinUsHomePage .workEnvironment .workEnvironmentContent .workEnvironmentContentSwiper {
  width: 9.12rem;
  height: 4.266667rem;
}

.mobile .JoinUsHomePage .workEnvironment .workEnvironmentContent .workEnvironmentContentPagination {
  margin-top: 0.533333rem;
  margin-bottom: 0.8rem;
  height: 0.106667rem;
}

.mobile .JoinUsHomePage .workEnvironment .workEnvironmentContent .workEnvironmentContentPagination .swiper-pagination-bullet {
  width: 0.533333rem;
  height: 0.106667rem;
}

.mobile .JoinUsHomePage .employeeWelfare {
  height: auto;
  padding-top: 0.586667rem;
}

.mobile .JoinUsHomePage .employeeWelfare .title {
  line-height: 0.8rem;
  font-size: 0.586667rem;
  color: #333;
  margin-bottom: 0.8rem;
}

.mobile .JoinUsHomePage .employeeWelfare .content {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.mobile .JoinUsHomePage .employeeWelfare .content .item {
  width: 3.066667rem;
  margin-bottom: 0.906667rem;
}

.mobile .JoinUsHomePage .employeeWelfare .content .item .icon {
  width: 1.066667rem;
  height: 1.066667rem;
  background: gray;
  margin: 0 auto;
}

.mobile .JoinUsHomePage .employeeWelfare .content .item .text {
  margin-top: 0.4rem;
  line-height: 0.533333rem;
  font-size: 0.373333rem;
  color: #333;
}

.mobile .JoinUsHomePage .staffDevelopment {
  padding-top: 0.8rem;
}

.mobile .JoinUsHomePage .staffDevelopment .title {
  line-height: 0.8rem;
  font-size: 0.586667rem;
  color: #333;
  margin-bottom: 0.56rem;
}

.mobile .JoinUsHomePage .staffDevelopment .content {
  flex-wrap: wrap;
  justify-content: space-between;
}

.mobile .JoinUsHomePage .staffDevelopment .content .item {
  width: 4.506667rem;
  height: 3.386667rem;
  margin-bottom: 0.186667rem;
  font-size: 0.373333rem;
  line-height: 0.48rem;
  color: #fff;
}

.mobile .JoinUsHomePage .staffDevelopment .content .item .hover.staffDevelopmentContent,
.mobile .JoinUsHomePage .staffDevelopment .content .item .staffDevelopmentContent.staffDevelopmentContent {
  padding-top: 2.693333rem;
}

.mobile .JoinUsIndexPage {
  margin-top: 0.8rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation {
  padding-bottom: 0.8rem;
  width: 9.2rem;
  margin: 0 auto;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .title {
  font-size: 0.586667rem;
  color: #333;
  line-height: 0.8rem;
  font-weight: bold;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .info {
  line-height: 0.56rem;
  font-size: 0.4rem;
  color: #333;
  margin-top: 0.4rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation pre {
  white-space: pre-wrap;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .hideContent {
  font-size: 0.4rem;
  line-height: 0.773333rem;
  color: #919191;
  margin-top: 0.213333rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .showContent {
  background: #f2f3f5;
  padding: 0.533333rem 0;
  margin-top: 0.426667rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .showContent .inlineTitle {
  font-size: 0.48rem;
  line-height: 0.773333rem;
  padding: 0.266667rem 0;
  margin-left: 0.4rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .showContent .inlineTitle::before {
  width: 0.853333rem;
  height: 0.053333rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .showContent .inlineText {
  font-size: 0.4rem;
  line-height: 0.773333rem;
  margin: 0.4rem 0.16rem 0.4rem 0.4rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .showContent .copy {
  margin-left: 0.4rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .showContent .copy .inlineBodyTitle {
  font-size: 0.453333rem;
  line-height: 0.773333rem;
  margin-right: 0.8rem;
}

.mobile .JoinUsIndexPage .RecruitmentInformation .showContent .copy .copyButton {
  padding-left: 0.746667rem;
  line-height: 0.56rem;
  font-size: 0.4rem;
  color: #1c5db9;
  background: url("../../assets/images/copy@2x.png") no-repeat left center/0.586667rem 0.586667rem;
  cursor: pointer;
}

.mobile .NewsPage {
  padding-bottom: 0.8rem;
  padding-top: 1.493333rem;
  background-color: #f8f8f8;
}

.mobile .NewsPage .list {
  margin-top: 0.533333rem;
}

.mobile .NewsPage .list .item {
  display: block;
  height: auto;
  background-color: #fff;
  border-radius: 0.08rem;
  overflow: hidden;
  margin-bottom: 0.533333rem;
}

.mobile .NewsPage .list .item .icon {
  width: 9.2rem;
  height: 5.52rem;
  border-radius: 0;
}

.mobile .NewsPage .list .item .infoBox {
  width: 9.2rem;
  margin: 0;
}

.mobile .NewsPage .list .item .infoBox .title {
  font-size: 0.48rem;
  color: #333;
  line-height: 0.573333rem;
  white-space: normal;
  max-lines: 2;
  margin-top: 0.533333rem;
  font-weight: bold;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.mobile .NewsPage .list .item .infoBox .des {
  margin-top: 0.4rem;
  font-size: 0.4rem;
  line-height: 0.613333rem;
  color: #979797;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}

.mobile .NewsPage .list .item .infoBox .more_bg {
  position: relative;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  margin-top: 0.533333rem;
  margin-right: 0.4rem;
  margin-bottom: 0.4rem;
}

.mobile .NewsPage .list .item .infoBox .more_bg .more_img {
  margin-left: 0.133333rem;
  width: 0.32rem;
  height: 0.32rem;
}

.mobile .NewsPage .list .item .infoBox .bottomIcon {
  display: none;
}

.mobile .NewsPage .list .item:hover .icon img {
  transform: scale(1);
}

.mobile .NewsPage .list .item:hover .infoBox .title {
  color: #333;
}

.mobile .NewsPage .list .item:hover .infoBox .des {
  color: #979797;
}

.mobile .NewsPage .list .item:hover .infoBox .more_bg .more_title {
  color: #919191;
  transform: translateX(0);
}

.mobile .NewsPage .list .item:hover .infoBox .more_bg .more_img {
  background-image: url("../../assets/home/more.png");
  transform: translateX(0);
}

.mobile .NewsPage .more_button_page_bg {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0.533333rem;
}

.mobile .NewsPage .more_button_page_bg .more_button_bg {
  display: flex;
  width: 3.733333rem;
  height: 1.066667rem;
  background-color: #ecf3fe;
  font-size: 0.373333rem;
  font-weight: 500;
  color: #1c5db9;
  line-height: 0.533333rem;
  letter-spacing: 0.053333rem;
  align-items: center;
  justify-content: center;
}

.mobile .NewsPage .Pagination {
  display: none;
}

.mobile .NewsDetailPage {
  padding-top: 1.493333rem;
  padding-bottom: 0.8rem;
}

.mobile .NewsDetailPage .NewsDetailPageContent {
  width: 9.2rem;
  margin: 0 auto;
}

.mobile .NewsDetailPage .NewsDetailPageContent .back_mobile {
  display: block;
  font-size: 0.373333rem;
  color: #6495ed;
}

.mobile .NewsDetailPage .NewsDetailPageContent .back {
  display: none;
}

.mobile .NewsDetailPage .NewsDetailPageContent .title {
  font-size: 0.64rem;
  line-height: 0.96rem;
  color: #333;
  text-align: left;
  font-weight: bold;
}

.mobile .NewsDetailPage .NewsDetailPageContent .time {
  font-size: 0.373333rem;
  text-align: left;
  color: #7f848b;
  border-bottom: 0.026667rem solid #d8d9dc;
  padding-bottom: 0.4rem;
  margin-top: 0.533333rem;
}

.mobile .NewsDetailPage .NewsDetailPageContent .avatarContentUrl {
  margin-top: 0.4rem;
  width: 100%;
  border-radius: 0.266667rem;
  overflow: hidden;
}

.mobile .NewsDetailPage .NewsDetailPageContent .avatarContentUrl img {
  width: 100%;
  height: auto;
}

.mobile .NewsDetailPage .NewsDetailPageContent .newContentArticle {
  margin-top: 0.533333rem;
  font-size: 0.373333rem;
  color: #333;
  line-height: 0.586667rem;
}

.mobile .NewsDetailPage .NewsDetailPageContent .newContentArticle img {
  width: 100% !important;
  height: auto !important;
}

.mobile .SolutionBigDataChild .productBackground {
  width: 9.2rem;
  display: block;
  height: auto;
  padding: 0.8rem 0.4rem;
}

.mobile .SolutionBigDataChild .productBackground .img {
  width: 8.4rem;
  margin: 0;
  height: 5.733333rem;
}

.mobile .SolutionBigDataChild .productBackground .info {
  margin-top: 0.4rem;
}

.mobile .SolutionBigDataChild .productBackground .info .title {
  margin-bottom: 0.266667rem;
}

.mobile .SolutionBigDataChild .productIntroduction {
  height: auto;
  padding-bottom: 0.8rem;
}

.mobile .SolutionBigDataChild .productIntroduction .content {
  padding-top: 0.8rem;
}

.mobile .SolutionBigDataChild .productIntroduction .content .info {
  margin-top: 0.266667rem;
  width: 8.4rem;
}

.mobile .SolutionBigDataChild .productFeatures .content {
  display: block;
}

.mobile .SolutionBigDataChild .productFeatures .content .item {
  width: 9.2rem;
  height: 6.266667rem;
  line-height: 6.266667rem;
  margin-bottom: 0.4rem;
}

.mobile .SolutionBigDataChild .productFeatures .content .item .hover {
  padding-top: 0.4rem;
}

.mobile .SolutionBigDataChild .productFeatures .content .item .hover h1 {
  margin-top: 1.333333rem;
  font-size: 0.586667rem;
}

.mobile .SolutionBigDataChild .productFeatures .content .item .hover p {
  width: 8.533333rem;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productIntroduction {
  height: auto;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productIntroduction .content {
  background: none;
  height: auto;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productIntroduction .content h1 {
  text-align: center;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productIntroduction .content p {
  width: 8.4rem;
  margin: 0 auto;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productFeatures .content .item {
  width: 9.2rem;
  height: 4.2rem;
  line-height: 4.2rem;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productFeatures .content .item .hover {
  padding-top: 0.4rem;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productFeatures .content .item .hover h1 {
  margin-top: 0;
}

.mobile .SolutionBigDataChild.SolutionBigDataBusinessCabinet .productFeatures .content .item .hover p {
  width: 8.666667rem;
  margin: 0.4rem auto 0;
  font-size: 0.4rem;
  line-height: 0.533333rem;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile {
  background: rgba(0,0,0,.9);
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent {
  background-color: rgba(0,0,0,0);
  width: 100%;
  height: auto;
  border: none;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent .close {
  display: none;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent .title {
  margin: 0;
  font-size: 0.453333rem;
  padding: 0 0.4rem;
  color: #fff;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent .CompanyProfileTeamBuildingSwiper {
  width: 100%;
  height: 5.653333rem;
  padding: 0;
  margin: 0.533333rem 0 0;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent .CompanyProfileTeamBuildingSwiper .CompanyProfileTeamBuildingSwiperContent {
  width: 100%;
  height: 5.653333rem;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent .paging {
  height: 0.08rem;
  margin-top: 0.533333rem;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent .paging .swiper-pagination-bullet {
  width: 0.533333rem;
  height: 0.08rem;
}

.CompanyProfileTeamBuildingModal.CompanyProfileTeamBuildingModalMobile .modalContent .text {
  margin: 0.533333rem auto;
  font-size: 0.4rem;
  color: #fff;
  width: 9.2rem;
  line-height: 0.56rem;
}

.AchievementSwiperMobile {
  padding: 0;
  width: 10rem;
  overflow: hidden;
}

.AchievementSwiperMobile .leftButton,
.AchievementSwiperMobile .rightButton {
  display: none;
}

.AchievementSwiperMobile .swiperContent {
  width: 7.36rem;
  height: 4.757333rem;
  margin: 0 auto;
}

.AchievementSwiperMobile .swiperContent .item {
  width: 7.36rem;
  height: 4.757333rem;
  background-image: linear-gradient(90deg, #2a6ac5 0%, #6795d7 49%, #2a6ac5 100%);
  box-shadow: inset 0 0.026667rem 0.4rem 0 rgba(0,0,0,.35);
  border: solid 0.186667rem #c7cede;
  position: relative;
}

.AchievementSwiperMobile .swiperContent .item .bg {
  position: absolute;
  top: 0.053333rem;
  left: 0.053333rem;
  width: 6.954667rem;
  height: 4.373333rem;
  background: url("../../assets/images/huawen@2x.png") no-repeat center center/100% 100%;
  opacity: .4;
}

.AchievementSwiperMobile .swiperContent .item .bgColor {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #2a6ac5 0%, #6795d7 49%, #2a6ac5 100%);
}

.AchievementSwiperMobile .swiperContent .item .itemContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.AchievementSwiperMobile .swiperContent .item .itemContent .icon {
  width: 1.066667rem;
  height: 1.066667rem;
  margin: 1.066667rem auto 0;
}

.AchievementSwiperMobile .swiperContent .item .itemContent .itemName {
  margin-top: 0.266667rem;
  font-size: 0.426667rem;
  line-height: 0.64rem;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.AchievementSwiperMobile .infoBox {
  width: 9.2rem;
  border-radius: 0.133333rem;
  margin: 0.533333rem auto 0;
}

.AchievementSwiperMobile .infoBox .arrow {
  width: 0.773333rem;
  height: 0.426667rem;
  background: url("../../assets/images/jian@2x.png") no-repeat center center/100% 100%;
  margin: 0 auto;
  position: relative;
  bottom: -0.026667rem;
}

.AchievementSwiperMobile .infoBox .box {
  padding-top: 0.666667rem;
  background: #f2f3f5;
}

.AchievementSwiperMobile .infoBox .box .img {
  width: 6.933333rem;
  height: 4.533333rem;
  margin: 0 auto;
}

.AchievementSwiperMobile .infoBox .box .info .name {
  font-size: 0.453333rem;
  line-height: 0.64rem;
  color: #333;
  margin-top: 0.533333rem;
  font-weight: bold;
  text-align: center;
  padding: 0 0.4rem;
}

.AchievementSwiperMobile .infoBox .box .info .name pre {
  margin: 0;
}

.AchievementSwiperMobile .infoBox .box .info .text {
  padding: 0.4rem;
  font-size: 0.4rem;
  line-height: 0.693333rem;
  color: #333;
  margin: 0;
}