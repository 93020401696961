.SolutionBigDataBusinessCabinet {
  margin-top: 30px;
  background: #fff;

  .headTitle {
    font-size: 34px;
    letter-spacing: 1px;
    color: #333333;
    margin-top: 78px;
    text-align: center;
    font-weight: bold;
  }

  .headLabel {
    font-size: 16px;
    line-height: 17px;
    color: #333333;
    text-align: center;
    margin-top: 19px;
  }

  .productBackground {
    margin: 55px auto 75px;
    width: 1200px;
    height: 319px;
    background-color: #f2f3f5;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;

    .img {
      width: 342px;
      height: 232px;
      margin-left: 40px;
      margin-right: 52px;
      background: #f2f3f5 url("../../../../../../assets/images/thebox_img1@2x.jpg") no-repeat center center / 100% 100%;
    }

    .info {
      .title {
        font-size: 26px;
        color: #333333;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .msg {
        font-size: 15px;
        line-height: 24px;
        color: #333333;
      }
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      width: 36.5px;
      height: 36.5px;
      background: #f2f3f5;
      top: -15px;
      left: 50%;
      margin-left: -11px;
      transform: rotate(45deg);
    }
  }

  .productIntroduction {
    width: 100%;
    height: 319px;
    background: #f5ece1;

    .content {
      height: 319px;
      background: url("../../../../../../assets/images/thebox_bg@2x.jpg") no-repeat top right / auto 100%;
      padding-top: 87px;

      h1 {
        font-size: 26px;
        color: #333333;
        font-weight: bold;
        margin-bottom: 36px;
      }

      p {
        width: 687px;
        font-size: 15px;
        line-height: 24px;
        color: #333333;
      }
    }
  }

  .productFeatures {
    padding-top: 78px;
    padding-bottom: 74px;

    .title {
      font-size: 26px;
      color: #333333;
      font-weight: bold;
      text-align: center;
      margin-bottom: 45px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        width: 592px;
        height: 271px;
        background: url("../../../../../../assets/images/thebox_img2@2x.png") no-repeat center center / 100% 100%;
        color: #fff;
        text-align: center;
        line-height: 271px;
        font-size: 22px;
        letter-spacing: 1px;
        font-weight: bold;
        position: relative;
        cursor: pointer;
        margin-bottom: 18px;

        .hover {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          transition: all .5s ease;
          background: #1c5db9;
          padding-top: 85px;

          h1 {
            font-size: 22px;
            letter-spacing: 1px;
            font-weight: bold;
            line-height: 24px;
          }

          p {
            width: 416px;
            font-size: 15px;
            line-height: 24px;
            color: #ffffff;
            margin: 33px auto 0;
            text-align: left;
            //text-align-last: left;
            //text-justify: inter-ideograph;

          }
        }

        &:nth-child(2) {
          background: url("../../../../../../assets/images/thebox_img3@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(3) {
          background: url("../../../../../../assets/images/thebox_img4@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(4) {
          background: url("../../../../../../assets/images/thebox_img5@2x.png") no-repeat center center / 100% 100%;
        }

        &:hover {
          .hover {
            opacity: 1;
          }
        }
      }
    }
  }

}
