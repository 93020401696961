.hundred_container_mobile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 244px;
    justify-content: space-between;
    align-items: center;
    max-width: 430px;
    .hundred_item {
        display: flex;
        flex-direction: row;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 20px;
        margin-bottom: 10px;
        width: 160px;
        .hundred_item_icon {
            width: 30px;
            height: 30px;
            margin-top: 10px;
        }
        .hundred_item_right {
            display: flex;
            flex-direction: column;
            margin-left: 9px;
            .hundred_item_title {
                font-size: 20px;
                font-weight: bold;
                color: #ffffff;
                line-height: 28px;
            }
            .hundred_item_desc {
                font-size: 12px;
                font-weight: 400;
                color: #ffffff;
                line-height: 17px;
                margin-top: 4px;
            }
        }
    }
}
