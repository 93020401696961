.CustomerCase {
  margin-top: 70px;
  margin-bottom: 100px;
  .customerCaseTitle {
    font-weight: bold;
    text-align: center;
    font-size: 34px;

    font-weight: 600;
    color: #333333;
    line-height: 36px;
    margin-bottom: 60px;
  }

  .customerCaseContent {
    .ani {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 126px;
    }
  }
}


