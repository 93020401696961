.pc{
  min-width: 960px!important;
}
.content{
  width: 960px;
  margin: 0 auto;
}

@media only screen and (max-width:1140px){
  .pc{
    .content {width: 960px!important}
    .NewsDetailPageContent {width: 960px!important}
    // .CompanyProfileIndexPage{width: calc(960px - 300px)!important}
    .nc_container{width: 960px!important}
    .partnerCase{width: 960px!important}
    .k12_modular_content{width: 960px!important}

    .CompanyProfileHonorPage .list .item{
      width: 236px!important;
      height: 166px!important;
    }
    .CompanyProfileHonorPage .list .item:nth-child(2n) {
      margin-right: 0!important;
    }
    
    // .hundred_container_adapted_box{ width: 960px!important;flex-wrap: wrap;}
    // .hundred_container_adapted_box .hundred_item{
    //   width: 50%;
    //   margin-bottom: 20px;
    // }
    // .technology_content_bg_adapted_wrap{
    //   width: 960px!important;
    //   height: 186px!important;
    // }
    // .technology_content_bg{
    //   transform: scale(0.555) translate(-50%,-50%)!important;
    // }
    // .safe_content_bg_adapted_wrap,.life_content_bg_adapted_wrap{
    //   width: 960px!important;
    //   height: 332px!important;
    // }
    // .safe_content_bg,.life_content_bg{
    //   transform: scale(0.555) translate(-50%,-50%)!important;
    // }
    // .risk_content_adapted_wrap{
    //   width: 960px!important;
    //   height: 531px!important;
    // }
    // .risk_content_adapted_box{
    //   transform: scale(0.555) translate(-50%,-50%)!important;
    // }
    // .risk_container .risk_img{
    //   height: 531px!important;
    // }
    // .business_chart_adapted_wrap{
    //   width: 666px!important;
    //   height: 536px!important;
    // }
    // .business_chart_adapted_box{
    //   transform: scale(0.555) translate(-50%,-50%)!important;
    // }

    
    .statistics_adapted_wrap{
      width: 960px!important;
      height: 109px!important;
    }
    .statistics_adapted_box {
      transform: scale(0.555) translate(-50%,-50%)!important;
    }
    .PublicWelfareColumn{
      height: 333px!important;
    }
    .welfare_adapted_wrap{
      width: 960px!important;
      height: 333px!important;
    }
    .welfare_adapted_box{
      transform: scale(0.555) translate(-50%,-50%)!important;
    }
    /// 新适配
    .CustomerCase{width: 960px!important}
    
    .SocialWelfarePage .statistics .content .item {
      width: 240px!important;
    }
    .projects .layer-title {
      width: 960px!important;
    }
    .projects .adapted_wrap {
      margin-top: 70px;
      height: 881px!important;
      position: relative;
    }
    .projects .adapted_box {
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
    .expertTeam_adapted_wrap{
      width: 960px!important;
      height: 1155px!important;
      position: relative;
      margin: 0 auto;
    }
    .expertTeam_adapted_box{
      width: 1440px;
      height: 1733px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
    .tab-partner-content .tab-item {
      width: 305px!important;
      height: 91px!important;
    }
    .tab-partner-content i {
      width: 305px!important;
    }
    .saas_container_adapted_wrap{
      width: 960px!important;
      height: 444px!important;
      position: relative;
      margin: 0 auto;
    }
    .saas_container_adapted_box{
      width: 1440px;
      height: 666px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
    .hundred_container {
      flex-wrap: wrap;
      .hundred_item {
        width: 50%!important;
        padding-left: 100px;
      }
    }
    .technology_adapted_wrap{
      width: 960px;
      height: 763px;
      position: relative;
      margin: 0 auto;
    }
    .technology_adapted_box{
      width: 1440px;
      height: 1144px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
    .safe_container_adapted_wrap{
      width: 960px;
      height: 617px;
      position: relative;
      margin: 0 auto;
    }
    .safe_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
    .life_container_adapted_wrap{
      width: 960px;
      height: 617px;
      position: relative;
      margin: 0 auto;
    }
    .life_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
    .risk_container_adapted_wrap{
      width: 960px;
      height: 780px;
      position: relative;
      margin: 0 auto;
    }
    .risk_container_adapted_box{
      width: 1440px;
      height: 1170px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
    .business_container_adapted_wrap{
      width: 960px;
      height: 772px;
      position: relative;
      margin: 0 auto;
    }
    .business_container_adapted_box{
      width: 1440px;
      height: 1158px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }

  }
  // 0.66666
}
@media only screen and (min-width:1140px) and (max-width:1300px){
  .pc{
    .content {width: 1120px!important} 
    .NewsDetailPageContent {width: 1120px!important}
    // .CompanyProfileIndexPage{width: calc(1120px - 300px)!important}
    // .saas_container{width: 1120px!important}
    .nc_container{width: 1120px!important}
    .partnerCase{width: 1120px!important}
    .k12_modular_content{width: 1120px!important}

    .CompanyProfileHonorPage .list .item{
      width: 305px!important;
      height: 223px!important;
    }
    .CompanyProfileHonorPage .list .item:nth-child(2n) {
      margin-right: 0!important;
    }
    // .saas_container_adapted_wrap{
    //   width: 1120px!important;
    //   height: 300px!important;
    // }
    // .saas_container_adapted_box{
    //   transform: scale(0.666) translate(-50%,-50%)!important;
    // }
    // .hundred_container_adapted_box{ width: 1120px!important}
    // .technology_content_bg_adapted_wrap{
    //   width: 1120px!important;
    //   height: 223px!important;
    // }
    // .technology_content_bg{
    //   transform: scale(0.666) translate(-50%,-50%)!important;
    // }
    // .safe_content_bg_adapted_wrap,.life_content_bg_adapted_wrap{
    //   width: 1120px!important;
    //   height: 398px!important;
    // }
    // .safe_content_bg,.life_content_bg{
    //   transform: scale(0.666) translate(-50%,-50%)!important;
    // }
    // .risk_content_adapted_wrap{
    //   width: 1120px!important;
    //   height: 637px!important;
    // }
    // .risk_content_adapted_box{
    //   transform: scale(0.666) translate(-50%,-50%)!important;
    // }
    // .risk_container .risk_img{
    //   height: 637px!important;
    // }
    // .business_chart_adapted_wrap{
    //   width: 799px!important;
    //   height: 643px!important;
    // }
    // .business_chart_adapted_box{
    //   transform: scale(0.666) translate(-50%,-50%)!important;
    // }
    
    .statistics_adapted_wrap{
      width: 1120px!important;
      height: 131px!important;
    }
    .statistics_adapted_box {
      transform: scale(0.666) translate(-50%,-50%)!important;
    }
    .PublicWelfareColumn{
      height: 400px!important;
    }
    .welfare_adapted_wrap{
      width: 960px!important;
      height: 400px!important;
    }
    .welfare_adapted_box{
      transform: scale(0.666) translate(-50%,-50%)!important;
    }
    /// 新适配👇🏻
    .CustomerCase{width: 1120px!important}
    
    .SocialWelfarePage .statistics .content .item {
      width: 280px!important;
    }
    .projects .layer-title {
      width: 1120px!important;
    }
    .projects .adapted_wrap {
      margin-top: 70px!important;
      height: 1028px!important;
      position: relative;
    }
    .projects .adapted_box {
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
    .expertTeam_adapted_wrap{
      width: 1120px!important;
      height: 1348px!important;
      position: relative;
      margin: 0 auto;
    }
    .expertTeam_adapted_box{
      width: 1440px;
      height: 1733px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
    .tab-partner-content .tab-item{
      width: 355px!important;
      height: 106px!important;
    }
    .tab-partner-content i {
      width: 355px!important;
    }
    .saas_container_adapted_wrap{
      width: 1120px!important;
      height: 518px!important;
      position: relative;
      margin: 0 auto;
    }
    .saas_container_adapted_box{
      width: 1440px;
      height: 666px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
    .technology_adapted_wrap{
      width: 1120px;
      height: 890px;
      position: relative;
      margin: 0 auto;
    }
    .technology_adapted_box{
      width: 1440px;
      height: 1144px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
    .safe_container_adapted_wrap{
      width: 1120px;
      height: 719px;
      position: relative;
      margin: 0 auto;

    }
    .safe_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
    .life_container_adapted_wrap{
      width: 1120px;
      height: 719px;
      position: relative;
      margin: 0 auto;
    }
    .life_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
    .risk_container_adapted_wrap{
      width: 1120px;
      height: 910px;
      position: relative;
      margin: 0 auto;
    }
    .risk_container_adapted_box{
      width: 1440px;
      height: 1170px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
    .business_container_adapted_wrap{
      width: 1120px;
      height: 900px;
      position: relative;
      margin: 0 auto;
    }
    .business_container_adapted_box{
      width: 1440px;
      height: 1158px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.777) translate(-50%,-50%);
    }
  }
  // 0.777
}
@media only screen and (min-width:1300px) and (max-width:1460px){
  .pc{
    .content {width: 1280px!important} 
    .NewsDetailPageContent {width: 1280px!important}
    // .CompanyProfileIndexPage{width: calc(1280px - 300px)!important}
    // .saas_container{width: 1280px!important}
    .nc_container{width: 1280px!important}
    .partnerCase{width: 1280px!important}
    .k12_modular_content{width: 1280px!important}
    // .risk_model_bg{width: 1280px!important}
    .CompanyProfileHonorPage .list .item{
      width: 310px!important;
      height: 223px!important;
    }
    .CompanyProfileHonorPage .list .item:nth-child(3n) {
      margin-right: 0!important;
    }
    // .saas_container_adapted_wrap{
    //   width: 1280px!important;
    //   height: 400px!important;
    // }
    // .saas_container_adapted_box{
    //   transform: scale(0.888) translate(-50%,-50%)!important;
    // }
    // .hundred_container_adapted_box{ width: 1280px!important}
    // .technology_content_bg_adapted_wrap{
    //   width: 1280px!important;
    //   height: 297px!important;
    // }
    // .technology_content_bg{
    //   transform: scale(0.888) translate(-50%,-50%)!important;
    // }
    // .safe_content_bg_adapted_wrap,.life_content_bg_adapted_wrap{
    //   width: 1280px!important;
    //   height: 530px!important;
    // }
    // .safe_content_bg,.life_content_bg{
    //   transform: scale(0.888) translate(-50%,-50%)!important;
    // }
    // .risk_content_adapted_wrap{
    //   width: 1280px!important;
    //   height: 850px!important;
    // }
    // .risk_content_adapted_box{
    //   transform: scale(0.888) translate(-50%,-50%)!important;
    // }
    // .risk_container .risk_img{
    //   height: 850px!important;
    // }
    // .business_chart_adapted_wrap{
    //   width: 1066px!important;
    //   height: 857px!important;
    // }
    // .business_chart_adapted_box{
    //   transform: scale(0.888) translate(-50%,-50%)!important;
    // }

    
    .statistics_adapted_wrap{
      width: 1280px!important;
      height: 175px!important;
    }
    .statistics_adapted_box {
      transform: scale(0.888) translate(-50%,-50%)!important;
    }
    .PublicWelfareColumn{
      height: 533px!important;
    }
    .welfare_adapted_wrap{
      width: 1280px!important;
      height: 533px!important;
    }
    .welfare_adapted_box{
      transform: scale(0.888) translate(-50%,-50%)!important;
    }
    /// 新适配👇🏻
    .CustomerCase{width: 1280px!important}
    
    .SocialWelfarePage .statistics .content .item {
      width: 320px;
    }
    .projects .layer-title {
      width: 1280px!important;
    }
    .projects .adapted_wrap {
      margin-top: 70px!important;
      height: 1175px!important;
      position: relative;
    }
    .projects .adapted_box {
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.888) translate(-50%,-50%);
    }
    .expertTeam_adapted_wrap{
      width: 1280px!important;
      height: 1539px!important;
      position: relative;
      margin: 0 auto;
    }
    .expertTeam_adapted_box{
      width: 1440px;
      height: 1733px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.888) translate(-50%,-50%);
    }
    .tab-partner-content .tab-item {
      width: 300px!important;
      height: 89px!important;
    }
    .tab-partner-content i {
      width: 300px!important;
    }
    .saas_container_adapted_wrap{
      width: 1280px!important;
      height: 592px!important;
      position: relative;
      margin: 0 auto;
    }
    .saas_container_adapted_box{
      width: 1440px;
      height: 666px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.888) translate(-50%,-50%);
    }
    .technology_adapted_wrap{
      width: 1280px;
      height: 1017px;
      position: relative;
      margin: 0 auto;
    }
    .technology_adapted_box{  
      width: 1440px;
      height: 1144px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.888) translate(-50%,-50%);
    }
    .safe_container_adapted_wrap{
      width: 1280px;
      height: 822px;
      position: relative;
      margin: 0 auto;
    }
    .safe_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.888) translate(-50%,-50%);
    }
    .life_container_adapted_wrap{
      width: 1280px;
      height: 822px;
      position: relative;
      margin: 0 auto;
    }
    .life_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.888) translate(-50%,-50%);
    }
    .risk_container_adapted_wrap{
      width: 1280px;
      height: 1040px;
      position: relative;
      margin: 0 auto;
    }
    .risk_container_adapted_box{
      width: 1440px;
      height: 1170px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.888) translate(-50%,-50%);
    }
    .business_container_adapted_wrap{
      width: 1280px;
      height: 1029px;
      position: relative;
      margin: 0 auto;
    }
    .business_container_adapted_box{
      width: 1440px;
      height: 1158px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(0.666) translate(-50%,-50%);
    }
  }
  
  // 0.888
}
@media screen and (min-width:1460px){
  .pc{
    .content {width: 1440px!important}
    .NewsDetailPageContent{width: 1440px!important}
    // .CompanyProfileIndexPage{width: calc(1440px - 300px)!important}
    // .saas_container{width: 1440px!important}
    .nc_container{width: 1440px!important}
    .partnerCase{width: 1440px!important}
    .k12_modular_content{width: 1440px!important}
    .CompanyProfileHonorPage .list .item{
      width: 356px!important;
      height: 251px!important;
    }
    .CompanyProfileHonorPage .list .item:nth-child(3n) {
      margin-right: 0!important;
    }
    // .saas_container_adapted_wrap{
    //   width: 1440px!important;
    //   height: 450px!important;
    // }
    // .saas_container_adapted_box{
    //   transform: scale(1) translate(-50%,-50%)!important;
    // }
    // .hundred_container_adapted_box{ width: 1440px!important}
    // .technology_content_bg_adapted_wrap{
    //   width: 1440px!important;
    //   height: 335px!important;
    // }
    // .technology_content_bg{
    //   transform: scale(1) translate(-50%,-50%)!important;
    // }
    // .safe_content_bg_adapted_wrap,.life_content_bg_adapted_wrap{
    //   width: 1440px!important;
    //   height: 597px!important;
    // }
    // .safe_content_bg,.life_content_bg{
    //   transform: scale(1) translate(-50%,-50%)!important;
    // }
    // .risk_content_adapted_wrap{
    //   width: 1440px!important;
    //   height: 957px!important;
    // }
    // .risk_content_adapted_box{
    //   transform: scale(1) translate(-50%,-50%)!important;
    // }
    // .risk_container .risk_img{
    //   height: 957px!important;
    // }
    // .business_chart_adapted_wrap{
    //   width: 1200px!important;
    //   height: 965px!important;
    // }
    // .business_chart_adapted_box{
    //   transform: scale(1) translate(-50%,-50%)!important;
    // }
    .statistics_adapted_wrap{
      width: 1440px!important;
      height: 197px!important;
    }
    .statistics_adapted_box {
      transform: scale(1) translate(-50%,-50%)!important;
    }
    .PublicWelfareColumn{
      height: 600px!important;
    }
    .welfare_adapted_wrap{
      width: 1440px!important;
      height: 600px!important;
    }
    .welfare_adapted_box{
      transform: scale(1) translate(-50%,-50%)!important;
    }
    /// 新适配👇🏻
    .CustomerCase{width: 1440px!important}
    
    .SocialWelfarePage .statistics .content .item {
      width: 360px!important;
    }
    .projects .layer-title {
      width: 1440px!important;
    }
    .projects .adapted_wrap {
      margin-top: 70px!important;
      height: 1322px!important;
      position: relative;
    }
    .projects .adapted_box {
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
    .expertTeam_adapted_wrap{
      width: 1400px!important;
      height: 1733px!important;
      position: relative;
      margin: 0 auto;
    }
    .expertTeam_adapted_box{
      width: 1440px;
      height: 1733px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
    .saas_container_adapted_wrap{
      width: 1440px!important;
      height: 666px!important;
      position: relative;
      margin: 0 auto;
    }
    .saas_container_adapted_box{
      width: 1440px;
      height: 666px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
    .technology_adapted_wrap{
      width: 1440px;
      height: 1144px;
      position: relative;
      margin: 0 auto;
    }
    .technology_adapted_box{  
      width: 1440px;
      height: 1144px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
    .safe_container_adapted_wrap{
      width: 1440px;
      height: 925px;
      position: relative;
      margin: 0 auto;

    }
    .safe_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
    .life_container_adapted_wrap{
      width: 1440px;
      height: 925px;
      position: relative;
      margin: 0 auto;
    }
    .life_container_adapted_box{
      width: 1440px;
      height: 925px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
    .risk_container_adapted_wrap{
      width: 1440px;
      height: 1170px;
      position: relative;
      margin: 0 auto;
    }
    .risk_container_adapted_box{
      width: 1440px;
      height: 1170px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
    .business_container_adapted_wrap{
      width: 1440px;
      height: 1158px;
      position: relative;
      margin: 0 auto;
    }
    .business_container_adapted_box{
      width: 1440px;
      height: 1158px;
      transform-origin: left top;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: scale(1) translate(-50%,-50%);
    }
  }
}
