.newsComponent {
  padding-top: 80px;
  padding-bottom: 80px;

  .title {
    text-align: center;

    .text {
      font-size: 38px;
      line-height: 38px;
      color: #333333;
      font-weight: bold;
    }

    .info {
      margin-top: 10px;
      font-size: 20px;
      line-height: 20px;
      color: #333333;
    }
  }

  .flexContent {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .swiperLeft {
      width: 530px;

      .swiperBox {
        width: 530px;
        height: 270px;

        .swiperBoxContent {
          width: 100%;
          height: 100%;

          .leftNewsItem {
            //cursor: pointer;
          }

          .swiper-button-prev,
          .swiper-button-next {
            position: absolute;
            width: 28px;
            height: 80px;
            opacity: 0.7;
            z-index: 2;
            background: #000 url("../../../../assets/images/left_page@2x.png") no-repeat center center / 20px 20px;
            top: 50%;
            margin-top: -40px;
            cursor: pointer;

            &.swiper-button-prev {
              left: 0;
            }

            &.swiper-button-next {
              right: 0;
              background: #000 url("../../../../assets/images/right_page@2x.png") no-repeat center center / 20px 20px;
            }
          }
        }
      }

      .time {
        font-size: 14px;
        color: #919191;
        line-height: 20px;
        margin: 15px 0 9px;
      }

      .title {
        width: 508px;
        font-size: 22px;
        line-height: 33px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 65px;
        text-align: left;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          color: #1c5db9;
        }
      }
    }

    .list {
      width: 597px;

      .item {
        height: 18px;
        margin-bottom: 30px;
        position: relative;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 8px;
          width: 5px;
          height: 5px;
          background: #015b9c;
        }

        .title {
          width: 432px;
          font-size: 16px;
          line-height: 18px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }

        .time {
          font-size: 16px;
          color: #919191;
        }

        &:hover {
          .title {
            color: #1c5db9;
          }
        }
      }
    }
  }

  .moreButton {
    display: block;
    width: 140px;
    height: 40px;
    border: solid 1px #1c5db9;
    font-size: 10px;
    letter-spacing: 2px;
    color: #1c5db9;
    text-align: center;
    line-height: 40px;
    margin: 56px auto 0;

    cursor: pointer;

    position: relative;

    &:hover {
      //background: #1c5db9;
      //color: #fff;

      &:before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #1c5db9 url("../../../../assets/images/more@2x.png") no-repeat center center / 16px 16px;
      }
    }
  }
}
