.so_container_mobile {
    display: flex;
    width: 100%;
    background: #f8f8f8;
    flex-direction: column;
    align-items: center;
    .so_top_bg_mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 220px;
        background-position: center center;
        background-size: 100% 220px;
        background-repeat: no-repeat;
        background-image: url("../../../../assets/solution/mobile/top_bg.png");
        .title_mobile {
            margin-top: 30px;
            font-size: 18px;
            font-family:
                PingFangSC,
                PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
        }
        .main_title_mobile {
            font-size: 20px;
            font-family:
                PingFangSC,
                PingFang SC;
            font-weight: 600;
            color: #ffffff;
            line-height: 20px;
            margin-top: 18px;
        }
        .sub_title_mobile {
            font-size: 13px;
            font-family:
                PingFangSC,
                PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 18px;
            margin-top: 7px;
            margin-left: 15px;
            margin-right: 12px;
            text-align: center;
        }
    }
}
