.life_container {
    display: flex;
    background: #f8f8f8;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-bottom: 120px;
    .life_title {
        margin-top: 120px;
        font-size: 34px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
    }
    .life_title_desc {
        font-size: 16px;
        // font-family:
        //     PingFangSC,
        //     PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-top: 16px;
    }
    .life_content_bg {
        display: flex;
        flex-direction: column;
        width: 1440px;
        .life_slider_bg {
            input {
                display: none;
            }
            position: relative;
            display: flex;
            margin-top: 85px;
            align-items: center;
            border-radius: 26px;
            height: 48px;
            color: #000000;
            width: 840px;
            overflow: hidden;
            label {
                width: 140px;
                height: 100%;
                border-radius: 26px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                letter-spacing: -1px;
                font-size: 24px;
                // font-family:
                //     PingFangSC,
                //     PingFang SC;
                font-weight: 600;
                line-height: 33px;
                color: #333333;
                transition: all 0.15s ease;
            }
            .selection {
                display: flex;
                position: absolute;
                height: 100%;
                width: 140px;
                z-index: 0;
                left: 0;
                top: 0;
                border-radius: 26px;
                transition: all 0.15s ease;
            }
            label:has(input:checked) {
                color: #fff;
            }
            label:has(input:checked) ~ .selection {
                background-color: #1c5db9;
            }
            @for $i from 1 through 6 {
                label:nth-child(#{$i}):has(input:checked) ~ .selection {
                    transform: translateX(calc(140px * (#{$i - 1})));
                }
            }
        }

        .life_content {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 78px;
            justify-content: space-between;

            .life_content_img_bg {
                width: 640px;
                height: 386px;
                overflow: hidden;
                flex-shrink: 0;
                .life_content_img {
                    width: 100%;
                    background-position: center;
                    background-size: cover;
                    transition: transform 0.5s ease;
                }
                &:hover .life_content_img {
                    transform: scale(1.05);
                }
            }
            .life_content_title {
                font-size: 32px;
                // font-family:
                //     PingFangSC,
                //     PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 50px;
            }
            .life_content_desc {
                font-size: 17px;
                // font-family:
                //     PingFangSC,
                //     PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 30px;
                margin-top: 30px;
                width: 730px;
            }
        }
    }
}
