.business_container {
    display: flex;
    background: white;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .business_title {
        margin-top: 105px;
        font-size: 34px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
    }
    .business_title_desc {
        font-size: 16px;
        // font-family:
        //     PingFangSC,
        //     PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-top: 16px;
    }
}
