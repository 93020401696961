.JoinUsHomePage {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #fff;

  .buttonBox {
    display: flex;
    margin-top: 60px;
    justify-content: space-between;

    .item {
      width: 586px;
      height: 250px;
      border: 1px solid #f2f3f5;
      cursor: pointer;
      position: relative;
      overflow: hidden;


      .buttonBoxContent, .bg {
        position: absolute;
        width: 100%;
        height: 100%;

        .title {
          font-size: 32px;
          color: #333333;
          line-height: 45px;
          font-weight: bold;
        }

        .info {
          margin-top: 9px;
          display: flex;
          align-items: center;

          .text {
            font-size: 12px;
            letter-spacing: 1px;
            color: #aeaeae;
            transition: all .5s ease;
          }

          .icon {
            width: 20px;
            height: 20px;
            margin-left: 8px;
            background: url("../../../../../assets/images/more_gray@2x.png") no-repeat center center / 100% 100%;
            transition: all .5s ease;
          }

        }

        &.buttonBoxContent {
          width: 200px;
          height: 158px;
          right: 0;
          bottom: 0;

          &:hover {
            .info {
              .text {
                color: #1c5db9;
              }

              .icon {
                margin-left: 15px;
              }

            }
          }
        }

        &.bg {
          left: 0;
          top: 0;
          width: 340px;
          height: 250px;
          transition: all .5s ease;
          background: url("../../../../../assets/images/join_img1@2x.png") no-repeat center center / 100% 100%;

          &:hover {
            width: 340+20px;
            height: 250+20px;
          }

        }
      }


      &:nth-child(2) {
        .bg {
          background: url("../../../../../assets/images/join_img2@2x.png") no-repeat center center / 100% 100%;
        }
      }


    }
  }

  .workEnvironment {
    .title {
      font-size: 34px;
      color: #333333;
      line-height: 48px;
      font-weight: bold;
      margin-top: 80px;
      text-align: center;
    }

    .workEnvironmentContent {
      margin: 50px auto 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .workEnvironmentNavigation {
        width: 40px;
        height: 186px;
        cursor: pointer;
        background-color: #e4e8f1;;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 30px 30px;
        transition: all .5s ease;

        &.workEnvironmentPrev {
          background-image: url("../../../../../assets/images/left_page@2x.png");
        }

        &.workEnvironmentNext {
          background-image: url("../../../../../assets/images/right_page@2x.png");
        }

        &:hover {
          background-color: #d8dff0;
        }
      }

      .workEnvironmentContentSwiper {
        width: 900px;
        height: 420px;
      }
    }

    .workEnvironmentContentPagination {
      margin-top: 40px;
      margin-bottom: 59px;
      height: 4px;
      text-align: center;

      .swiper-pagination-bullet {
        cursor: pointer;
        display: inline-block;
        vertical-align: top;
        width: 20px;
        height: 4px;
        background: #f1f1f1;

        &.swiper-pagination-bullet-active {
          background: #1c5db9;
        }
      }
    }
  }

  .employeeWelfare {
    height: 340px;
    background-color: #fbfbfb;
    padding-top: 41px;

    .title {
      font-size: 34px;
      color: #333333;
      line-height: 48px;
      text-align: center;
      font-weight: bold;
      margin-bottom: 60px;
    }

    .content {
      display: flex;
      justify-content: space-between;

      .item {
        width: 112px;

        .icon {
          width: 60px;
          height: 60px;
          background: gray;
          margin: 0 auto;
        }

        .text {
          margin-top: 20px;
          font-size: 16px;
          color: #333333;
          text-align: center;
          line-height: 22px;
        }

        &:nth-child(1) {
          .icon {
            background: url("../../../../../assets/images/join_icon1@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(2) {
          .icon {
            background: url("../../../../../assets/images/join_icon2@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(3) {
          .icon {
            background: url("../../../../../assets/images/join_icon3@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(4) {
          .icon {
            background: url("../../../../../assets/images/join_icon4@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(5) {
          .icon {
            background: url("../../../../../assets/images/join_icon5@2x.png") no-repeat center center / 100% 100%;
          }
        }

      }
    }
  }

  .staffDevelopment {
    padding-top: 81px;

    .title {
      font-size: 34px;
      color: #333333;
      text-align: center;
      line-height: 48px;
    }

    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;

      .item {
        width: 292px;
        height: 220px;
        font-size: 22px;
        color: #ffffff;
        line-height: 30px;
        background: gray;
        text-align: center;
        position: relative;
        overflow: hidden;

        .hover, .staffDevelopmentContent {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          &.staffDevelopmentContent {
            padding-top: 175px;
          }

          &.hover {
            transition: all .5s ease;
          }
        }

        &:nth-child(1) {
          .hover {
            background: url("../../../../../assets/images/join_img3@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(2) {
          .hover {
            background: url("../../../../../assets/images/join_img4@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(3) {
          .hover {
            background: url("../../../../../assets/images/join_img5@2x.png") no-repeat center center / 100% 100%;

          }
        }

        &:nth-child(4) {
          .hover {
            background: url("../../../../../assets/images/join_img6@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:hover {
          .hover {
            width: 110%;
            height: 110%;
          }
        }
      }
    }
  }
}
