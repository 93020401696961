.saas_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
        font-size: 34px;
        font-weight: bold;
        // font-weight: 600;
        color: #333333;
        line-height: 36px;
        margin-top: 102px;
        margin-bottom: 78px;
    }
    .item {
        display: flex;
        flex-direction: column;
        width: 440px;
        height: 450px;
        background: #ffffff;
        border-radius: 3px;
        align-items: center;
        .item_title {
            display: flex;
            height: 80px;
            width: 100%;
            justify-content: center;
            align-items: center;
            background: linear-gradient(270deg, #f8fafe 0%, #dbe6fd 100%);
            font-size: 26px;
            // font-family:
            //     PingFangSC,
            //     PingFang SC;
            // font-weight: 600;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
        }
        // 管理平台
        .left_bg {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            .left_title {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 252px;
                height: 44px;
                background: #e9eff8;
                border-radius: 22px;
                font-size: 22px;
                // font-family:
                //     PingFangSC,
                //     PingFang SC;
                font-weight: bold;
                color: #333333;
                line-height: 36px;
                margin-top: 57px;
            }

            .left_content {
                display: flex;
                flex-direction: row;
                margin-top: 57px;
                .left_content_item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100px;
                    height: 110px;
                    border: 1px solid #e4e8f1;
                    margin-right: 20px;
                    transition: background-color 0.3s ease-in-out;
                    cursor: pointer;
                    .left_content_item_icon {
                        width: 40px;
                        height: 40px;
                    }
                    .left_content_item_title {
                        font-size: 14px;
                        // font-family:
                        //     PingFangSC,
                        //     PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 20px;
                        margin-top: 7px;
                    }
                    &:nth-child(3) {
                        margin-right: 0px;
                    }
                    &:hover {
                        background: #e9eff8;
                    }
                }
            }
        }
        // 身份
        .middle_bg {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            .middle_item {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: center;
                .middle_item_icon {
                    width: 70px;
                    height: 70px;
                }
                .middle_item_title {
                    font-size: 14px;
                    // font-family:
                    //     PingFangSC,
                    //     PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                    margin-top: 7px;
                }
            }
            .middle_student {
                position: relative;
                display: flex;
                width: 70px;
                height: 70px;
                background: #dbe7fd;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                font-size: 22px;
                // font-family:
                //     PingFangSC,
                //     PingFang SC;
                font-weight: bold;
                color: #1c5db9;
                line-height: 30px;
                margin-top: 15px;
                transition: all 0.3s ease-in-out;
                &:hover {
                    box-shadow: 0 0 0 8px rgba(240, 245, 255, 0.75);
                }
            }
        }
        // 应用场景
        .right_bg {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-left: 49px;
            margin-right: 49px;
            .right_content_item {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 114px;
                margin-bottom: 30px;
                cursor: pointer;
                .right_content_item_point {
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #1c5db9;
                }
                .right_content_item_title {
                    margin-left: 8px;
                    font-size: 18px;
                    // font-family:
                    //     PingFangSC,
                    //     PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 25px;
                    &:hover {
                        color: #1c5db9;
                    }
                }
                &:nth-child(-n + 3) {
                    margin-top: 60px;
                }
                &:nth-child(3n + 2) {
                    justify-content: center;
                }
                &:nth-child(3n + 3) {
                    justify-content: flex-end;
                }
            }
        }
    }
}
