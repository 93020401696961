.CustomNavigation {
  width: 100%;
  height: 80px;
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .MoveTop {
    position: fixed;
    right: 15px;
    bottom: 15px;
    width: 45px;
    height: 45px;
    background: url("../../../../assets/mobile/top@3x.png") no-repeat center center / 100% 100%;
  }

  &.showShadow {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }

  .CustomNavigationBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    margin: 0 auto;
  }

  .icon {
    width: 220px;
    height: 47px;
    //background: url("../../../../assets/images/logo@2x.png") no-repeat center center / 100% 100%;
    > img {
      width: 100%;
      height: 100%;
    }
    .icon_image {
      display: block;
    }
    .icon_image_active {
      display: none;
    }
  }

  .items {
    margin-top: 10px;
    display: flex;
    // margin-left: 20%;

    .item {
      font-size: 16px;
      color: #fff;
      margin-left: 58px;
      height: 38px;
      line-height: 26px;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 10%;
        bottom: 0;
        width: 0;
        height: 1px;
        background: #333;
        transition: all 0.5s linear;
      }

      &.active,
      &:hover {
        &:before {
          width: 80%;
          background: #fff;
        }
      }
    }
  }

  &.active {
    background: #fff;

    .icon {
      .icon_image {
        display: none;
      }
      .icon_image_active {
        display: block;
      }
    }

    .items {
      .item {
        color: #333;

        &.active,
        &:hover {
          &:before {
            width: 80%;
            background: #333;
          }
        }
      }
    }
  }
  //backTop
  .backTop {
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 60px;
    height: 60px;
    background-image: url("../../../../assets/home/back_top.png");
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;

    opacity: 0;
    transition: opacity 0.1s ease-in;
    z-index: 10;
  }
  .backTop_mobile {
    position: fixed;
    right: 15px;
    bottom: 20px;
    width: 40px;
    height: 40px;
    background-image: url("../../../../assets/home/back_top.png");
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    cursor: pointer;

    opacity: 0;
    transition: opacity 0.1s ease-in;
    z-index: 10;
  }
  .backTop_fade_in {
    opacity: 1;
  }
}
