.PublicWelfarePage {
  .headTitle {
    font-size: 34px;
    color: #333333;
    line-height: 34px;
    font-weight: bold;
    text-align: center;
  }

  .headInfo {
    font-size: 15px;
    line-height: 27px;
    color: #333333;
    margin-top: 27px;
  }

  .PublicWelfarePageContent {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;

    .item {
      width: 207px;
      height: 292px;
      background-color: #f2f3f5;
      color: #333333;
      transition: all .5s ease;
      position: relative;
      .icon {
        width: 63px;
        height: 63px;
        background-color: #ffffff;
        margin: 81px auto 46px;
        position: relative;

        &:after {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0;
        }
      }

      .text {
        font-size: 24px;
        line-height: 33px;
        text-align: center;
      }

      .tip {
        display: none;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: #666;
        text-align: center;
        line-height: 295px;
        font-size: 18px;
        font-weight: bold;
      }

      &:nth-child(1) {
        .icon {
          background: url("../../../../../assets/images/welfare_icon1_blue@2x.png") no-repeat center center / 100% 100%;

          &:after {
            background: url("../../../../../assets/images/welfare_icon1_white@2x.png") no-repeat center center / 100% 100%;
          }
        }
      }

      &:nth-child(2) {
        .icon {
          background: url("../../../../../assets/images/welfare_icon2_blue@2x.png") no-repeat center center / 100% 100%;

          &:after {
            background: url("../../../../../assets/images/welfare_icon2_white@2x.png") no-repeat center center / 100% 100%;
          }
        }
      }

      &:nth-child(3) {
        .icon {
          background: url("../../../../../assets/images/welfare_icon3_blue@2x.png") no-repeat center center / 100% 100%;

          &:after {
            background: url("../../../../../assets/images/welfare_icon3_white@2x.png") no-repeat center center / 100% 100%;
          }
        }
      }

      &:nth-child(4) {
        .icon {
          background: url("../../../../../assets/images/welfare_icon4_blue@2x.png") no-repeat center center / 100% 100%;

          &:after {
            background: url("../../../../../assets/images/welfare_icon4_white@2x.png") no-repeat center center / 100% 100%;
          }
        }
      }

      &:hover, &.active {
        background: #1c5db9;
        color: #fff;
        .tip {
          display: block;
        }
        .icon {
          background: none;

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}
