.breadcrumbNavigation {
  width: 100%;
  height: 56px;
  background-color: #f2f3f5;

  .content {
    display: flex;

    .item {
      display: flex;
      height: 56px;
      align-items: center;
      font-size: 15px;
      color: #666666;
      cursor: pointer;

      .breadcrumbNavigationTitle {
        margin-right: 16px;
      }

      .icon {
        margin-right: 19px;
      }
    }
  }
}
