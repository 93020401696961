.safe_container_mobile {
    display: flex;
    background: white;
    align-items: center;
    flex-direction: column;
    max-width: 430px;
    padding-bottom: 30px;
    .safe_title {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
    }
    .safe_title_desc {
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
    }
    .safe_content_bg {
        display: flex;
        flex-direction: column;
        align-items: center;
        // width: 375px;
        .safe_slider_bg {
            input {
                display: none;
            }
            // margin-left: 15px;
            // margin-right: 15px;
            position: relative;
            display: flex;
            margin-top: 30px;
            align-items: center;
            border-radius: 26px;
            height: 32px;
            color: #000000;
            // width: 375px;
            overflow: hidden;
            width: calc(100vw - 30px);
            overflow-x: scroll;
            scroll-snap-type: x mandatory; /* 激活滑动对齐 */
            -webkit-overflow-scrolling: touch;
            // background-color: red;
            &::-webkit-scrollbar {
                display: none;
            }
            label {
                flex-shrink: 0; /* 阻止flex项目缩小，确保label宽度为设置的宽度 */
                width: calc((100vw - 30px) / 4);
                // width: 90px;
                height: 100%;
                border-radius: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                letter-spacing: -1px;
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                scroll-snap-align: center; /* 中心对齐 */
            }
            .selection {
                display: flex;
                position: absolute;
                height: 100%;
                width: calc((100vw - 30px) / 4);
                // width: 90px;
                z-index: 0;
                left: 0;
                top: 0;
                border-radius: 26px;
            }
            label:has(input:checked) {
                color: #fff;
            }
            label:has(input:checked) ~ .selection {
                background-color: #1c5db9;
            }
            @for $i from 1 through 6 {
                label:nth-child(#{$i}):has(input:checked) ~ .selection {
                    transform: translateX(calc((100vw - 30px) / 4 * (#{$i - 1})));
                }
            }
        }

        .safe_content {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            justify-content: center;
            // width: 345px;
            margin-left: 15px;
            margin-right: 15px;
            .safe_content_img_bg {
                width: 100%;
                height: 208px;
                overflow: hidden;
                flex-shrink: 0;
                text-align: center;
                .safe_content_img {
                    background-position: center;
                    background-size: cover;
                }
            }
            .safe_content_title {
                margin-left: 10px;
                margin-top: 30px;
                font-size: 18px;
                font-weight: 600;
                color: #333333;
            }
            .safe_content_desc {
                margin-left: 10px;
                font-size: 13px;
                font-weight: 400;
                color: #333333;
                margin-top: 16px;
                font-family:
                    PingFangSC,
                    PingFang SC;
            }
        }
    }
}
