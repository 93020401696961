.CustomWelfareSwiper {
  width: 100%;
  height: 100%;

  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    width: 28px;
    height: 80px;
    opacity: 0.7;
    z-index: 2;
    background: #000 url("../../../../../../assets/images/left_page@2x.png") no-repeat center center / 20px 20px;
    top: 50%;
    margin-top: -40px;
    cursor: pointer;

    &.swiper-button-prev {
      left: 0;
    }

    &.swiper-button-next {
      right: 0;
      background: #000 url("../../../../../../assets/images/right_page@2x.png") no-repeat center center / 20px 20px;
    }
  }
}
