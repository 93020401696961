.DigitalFinancePage {
  .headTitle {
    font-size: 34px;
    color: #333333;
    line-height: 34px;
    font-weight: bold;
    text-align: center;
  }

  .headInfo {
    font-size: 15px;
    line-height: 27px;
    color: #333333;
    margin-top: 27px;
    text-align: center;
  }

  .DigitalFinancePageContent {
    margin-top: 30px;
    margin-bottom: 80px;

    .digitalFinancePageBanner {
      height: 346px;
      overflow: hidden;
      position: relative;

      .bannerItem {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all .5s ease;

        &:nth-child(1) {
          background: url("../../../../../assets/images/finance_img1@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(2) {
          background: url("../../../../../assets/images/finance_img2@2x.png") no-repeat center center / 100% 100%;
        }

        &.active {
          opacity: 1;
        }
      }
    }

    .infoContent {
      display: flex;
      background: #e4e8f1;

      .item {
        width: 50%;
        height: 301px;
        padding-top: 56px;
        padding-left: 70px;
        cursor: pointer;
        transition: background .5s ease;

        .title {
          font-size: 24px;
          color: #333333;
          line-height: 24px;
          font-weight: bold;
        }

        .info {
          width: 309px;
          font-size: 16px;
          line-height: 28px;
          color: #333333;
          margin-top: 16px;
        }

        &.active {
          background: #f6f6f6;
          border-bottom: 4px solid #1c5db9;
        }
      }
    }
  }
}
