.PublicWelfareColumn {
  height: 600px;
  position: relative;
  background: #fff;
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .welfare_adapted_wrap{
    width: 1440px;
    height: 600px;
    margin: 0 auto;
    position: relative;
  }
  .welfare_adapted_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform-origin: left top;
    transform: scale(1) translate(-50%, -50%);
    width: 1440px;
    height: 405px;
    .infoBox {
      float: right;
      width: 700px;
      height: 303px;
      .title {
        span {
          height: 48px;
          line-height: 48px;
          font-size: 34px;
          color: #333333;
          font-weight: bold;
          text-decoration: none;
        }
        small {
          font-size: 14px;
          color: #666;
          margin-left: 10px;
        }
      }

      .info {
        font-size: 15px;
        line-height: 27px;
        color: #333333;
        margin-top: 20px;
        white-space: pre-wrap;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
      }

      .news {
        width: 462px;
        height: 85px;
        margin-top: 80px;
      }
    }

    .video {
      float: left;
      width: 700px;
      height: 405px;
      background-color: #0a1534;
      border: solid 5px #ffffff;
      box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
    }

    .swiper {
      float: left;
      width: 640px;
      height: 360px;
    }
  }

  &:nth-child(odd) {
    .content {
      .infoBox {
        float: left;
      }

      .video {
        float: right;
      }

      .swiper {
        float: right;
      }
    }
  }
}
