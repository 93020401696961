.homePage_mobile {
    background: #f8f8f8;
    position: relative;

    .topBannerModular_mobile {
        width: 100%;
        height: 395px;
        position: relative;
        .video_mobile {
            display: flex;
            width: 100%;
            height: 395px;
            object-fit: cover;
            border: none;
            outline: none;
            margin: 0;
            padding: 0;
        }
        .video_mask_mobile {
            position: absolute;
            width: 100%;
            height: 395px;
            object-fit: cover;
            border: none;
            outline: none;
            top: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.4);
        }
        .bg_mobile {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 395px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            z-index: 10;
            .bgTitle_mobile {
                font-size: 24px;
                font-weight: bold;
                color: #ffffff;
                letter-spacing: 3px;
                transition: opacity 0.5s ease;
            }
        }
    }

    //k12背景
    .k12_modular_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 360px;
        margin-top: 30px;
        background-image: url("../../../../assets/home/mobile/home_bg_mobile.png");
        background-size: fill;
        background-position: 0 0;
        background-repeat: no-repeat;
        .k12_modular_content_mobile {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            height: 100%;
            .title_mobile {
                font-size: 19px;
                margin-top: 80px;
                font-family:
                    PingFangSC,
                    PingFang SC;
                font-weight: 600;
                color: #ffffff;
                line-height: 26px;
            }
            .sub_title_mobile {
                font-size: 14px;

                font-weight: 400;
                color: #ffffff;
                line-height: 20px;
                margin-left: 27px;
                margin-right: 27px;
                margin-top: 30px;
            }
            .more_bg_mobile {
                position: absolute;
                align-items: center;
                display: flex;
                flex-direction: row;
                right: 27px;
                bottom: 74px;
                cursor: pointer;
                .more_title_mobile {
                    font-size: 14px;
                    font-weight: 500;
                    color: #ffffff;
                    line-height: 20px;
                    transition: transform 0.3s ease;
                }
                .more_img_mobile {
                    margin-left: 5px;
                    width: 12px;
                    height: 12px;
                    background-image: url("../../../../assets/home/more_white.png");
                    background-size: cover;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    transition: transform 0.3s ease;
                }
                &:hover {
                    .more_title_mobile {
                        transform: translateX(5px);
                    }
                    .more_img_mobile {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
    .bottom_nav_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 40px;
        .bottom_nav_item_mobile {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 44px;
            padding-left: 15px;
            padding-right: 15px;
            width: 100%;
            .title {
                font-size: 16px;
                font-weight: 500;
                color: #333333;
                line-height: 20px;
            }
            .icon {
                width: 12px;
                height: 12px;
                background-image: url("../../../../assets/home/more.png");
                background-size: cover;
                background-position: 0 0;
                background-repeat: no-repeat;
            }
        }
    }
}
