.CooperativePartner {
  background: #fbfbfb;
  padding: 50px 0 60px;

  .headTitle {
    font-size: 34px;
    color: #333333;
    font-weight: bold;
    text-align: center;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;

    .item {
      width: 285px;
      height: 85px;
      margin-right: 20px;
      margin-bottom: 20px;
      transition: all 300ms ease;
      background: #fff;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 0 7px 2px rgba(0, 0, 0, .07);
      }
    }
  }
}
