.DigitalCampusCenter {
  .title {
    font-size: 34px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    line-height: 34px;
    margin-top: 0;
  }

  .des {
    font-size: 15px;
    line-height: 27px;
    color: #333333;
    margin-top: 27px;
  }

  .schoolSecurity {
    width: 900px;
    height: 368px;
    background-color: #ffffff;
    border: solid 2px #f2f3f5;
    margin-top: 30px;
    display: flex;

    .left {
      width: 274px;
      background: url("../../../../assets/images/school_img01@2x.png") no-repeat center center / 100% 100%;
      padding-top: 136px;

      .title {
        height: 37px;
        font-size: 26px;
        color: #ffffff;
        font-weight: bold;
        margin-left: 58px;
        text-align: left;
        padding: 0;
        margin-top: 0;
      }

      .info {
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        margin-left: 58px;
        margin-top: 10px;
      }
    }

    .right {
      flex: 1;
      display: flex;
      flex-direction: column;

      .top {
        height: 78px;
        background-color: #e4e8f1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 40px;

        &.digitalCampusCenterSwiperPagination {
          .swiper-pagination-bullet {
            display: block;
            width: 114px;
            height: 34px;
            margin: 0 10px;
            cursor: pointer;
            color: #333;
            border-radius: 34px;
            position: relative;

            &:before {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              text-align: center;
              color: #333;
              line-height: 34px;
              font-size: 15px;
            }

            &.swiper-pagination-bullet-active {
              background: #1c5db9;

              &:before {
                color: #fff;
              }
            }

            &:nth-child(1) {
              &:before {
                content: '出入安全';
              }
            }

            &:nth-child(2) {
              &:before {
                content: '课堂安全';
              }
            }

            &:nth-child(3) {
              &:before {
                content: '资产安全';
              }
            }

            &:nth-child(4) {
              &:before {
                content: '信息安全';
              }
            }
          }
        }
      }

      .bottom {
        flex: 1;
        overflow: hidden;
        width: 622px;

        .itemContent {
          padding-top: 19px;
          padding-left: 49px;
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;

          .item {
            width: 109px;
            height: 120px;
            border: solid 1px #e4e8f1;
            margin-right: 30px;
            margin-bottom: 13px;

            .icon {
              height: 40px;
              width: auto;
              margin-top: 30px;

              img {
                display: block;
                height: 100%;
                width: auto;
                margin: 0 auto;
              }
            }

            .headTitle {
              font-size: 13px;
              color: #333333;
              margin-top: 13px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
