.hundred_adapted_wrap{
  background: #1c5db9;
  width: 100%;
}
.hundred_container {
    display: flex;
    height: 270px;
    .hundred_item {
        width: 25%;
        display: flex;
        flex-direction: row;
        align-items: center;
        .hundred_item_icon {
            width: 42px;
            height: 42px;
        }
        .hundred_item_right {
            display: flex;
            flex-direction: column;
            margin-left: 25px;
            .hundred_item_title {
                font-size: 26px;
                font-weight: bold;
                color: #ffffff;
                line-height: 45px;
            }
            .hundred_item_desc {
                font-size: 15px;
                font-weight: 400;
                color: #ffffff;
                line-height: 21px;
            }
        }
    }
}
