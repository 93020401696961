.CooperationPage {
  margin-top: 80px;
  background: #fff;

  .topCooperation {
    .title {
      font-size: 30px;
      line-height: 30px;
      color: #333333;
      margin-top: 14px;
      text-align: center;
      height: 120px;
      padding-top: 50px;
      background: url("../../../../assets/images/UNION@2x.png") no-repeat center 14px / 291px auto;
      margin-bottom: 46px;
      font-weight: bold;
    }
  }
}
