.business_container_mobile {
    display: flex;
    background: white;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .business_title {
        margin-top: 50px;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
    }
    .business_title_desc {
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
    }
}
