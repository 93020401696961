.content-wrap{
    display: flex;
    .left-box{
        width: 294px;
        height: 670px;
        flex-shrink: 0;
        .column-line{
            width: 3px;
            height: 670px;
            background: linear-gradient( 
                rgba(148, 175, 214,0) 0%,
                #94afd6 8%,
                #94afd6 92%,
                rgba(148, 175, 214,0) 100%);
            margin-left: 110px;
            position: relative;
            .row-box{
                width: 110px;
                height: 30px;
                position: absolute;
                left: -110px;
                text-indent: 12px;
                cursor: pointer;
                &::before{
                    content: "";
                    width: 18px;
                    height: 18px;
                    background-image: url("../../../../assets/icon/single2.png");
                    background-size: cover;
                    position: absolute;
                    top: 50%;
                    right: -1px;
                    transform: translate(50%,-50%);
                    transition: all 0.25s ease-in-out;
                }
                .year{
                    font-family: PingFangHK-Semibold;
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: rgba(51, 51, 51, 0.9);
                    line-height: 30px;
                    transition: all 0.25s ease-in-out;
                }
                &.active{
                    .year{
                        color: #1c5db9;
                        font-size: 24px;
                    }
                    &::before{
                        width: 30px;
                        height: 30px;
                        background-image: url("../../../../assets/icon/single.png");
                    }
                }
            }
            .position1{
                top: 70px;
            }
            .position2{
                top: 215px;
            }
            .position3{
                top: 360px;
            }
            .position4 {
              top: 505px;
            }
        }

    }
    .right-box{
        flex: 1;
        .right-content{
            padding-top: 55px;
            height: 100%;
            .content-title{
                height: 100px;
                font-family: PingFangHK-Semibold;
                font-size: 40px;
                font-weight: bold;
                font-stretch: normal;
                letter-spacing: 0px;
                color: rgba(51, 51, 51, 0.9);
            }
            .content-list {
                max-height: 510px;
                overflow: hidden;
                cursor: pointer;
                -ms-overflow-style: none;
                scrollbar-width: none;
                transition: max-height 0.25s ease-in-out;
                &.expand{
                    max-height: 2000px;
                }
                &::-webkit-scrollbar{
                    display: none;
                }
                .list-row{
                    font-family: PingFangSC-Regular;
                    font-size: 18px;
                    line-height: 1.5;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: rgba(51, 51, 51, 0.9);
                    position: relative;
                    padding-left: 26px;
                    &::after{
                        content: "";
                        width: 6px;
                        height: 6px;
                        background-color: #333333;
                        position: absolute;
                        left: 0;
                        top: 10px;
                    }


                    &:not(:last-child){
                        margin-bottom: 30px;
                    }
                }
            }
            .load-more{
                padding-top: 18px;
                font-size: 16px;
                color: #919191;
                padding-left: 200px;
                cursor: pointer;
                
            }
        }
    }
}