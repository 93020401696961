.technology_container {
    display: flex;
    background: #f8f8f8;
    align-items: center;
    flex-direction: column;
    .technology_title {
        margin-top: 120px;
        font-size: 34px;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
    }
    .technology_title_desc {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-top: 16px;
    }
    .technology_content_bg {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 1440px;
        justify-content: space-between;
        .technology_content_item {
            width: 350px;
            display: flex;
            flex-direction: column;
            align-items: center;
            // cursor: pointer;
            transition: background-color 0.15s ease-in-out;
            background-color: #ededed;
            padding: 36px 30px 55px 30px;
            background-color: rgba($color: #ededed, $alpha: 0);

            .technology_content_item_icon {
                width: 105px;
                height: 105px;
            }
            .technology_content_item_title {
                font-size: 24px;
                // font-family:
                //     PingFangSC,
                //     PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 30px;
                margin-top: 22px;
            }
            .technology_content_item_desc {
                font-size: 15px;
                // font-family:
                //     PingFangSC,
                //     PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 24px;
                margin-top: 15px;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
            &:hover {
                background-color: rgba($color: #ededed, $alpha: 0.7);
            }
        }
    }
}
