.footerComponent {
  background-color: #142c4f;

  .footerContent {
    height: 249px;
    display: flex;
    align-items: center;
    justify-content: center;

    .list {
      display: flex;

      .item {
        text-align: center;
        margin-right: 70px;

        .qr {
          width: 90px;
          height: 90px;
        }

        .text {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.4);
          margin-top: 15px;
          line-height: 12px;
        }
      }
    }

    .keepOnRecord {
      font-size: 14px;
      color: #ffffff;

      a {
        font-size: 14px;
        color: #ffffff;
      }
    }

    .iconPolice {
      width: 20px;
      height: 20px;
      //vertical-align: middle;
      margin-right: 5px;
    }
  }
}
