.NewsDetailPage {
  padding-bottom: 80px;
  // padding-top: 80px;
  background: #fff;

  .NewsDetailPageContent {
    display: flex;
    justify-content: center;
    min-height: 600px;
    padding-top: 70px;
    margin: 0 auto;
    position: relative;

    .back_mobile {
      display: none;
    }

    .back {
      display: block;
      position: absolute;
      left: 0px;
      top: 25px;
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5) url(../../../../../assets/images/left_page@2x.png) no-repeat center center / 30px
        30px;
      border-radius: 50px;
      cursor: pointer;
    }
    .content_bg {
      width: 1200px;
      .title {
        font-size: 38px;
        text-align: center;
        font-weight: bold;

        font-size: 38px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 53px;
      }

      .time {
        text-align: center;
        // border-bottom: 1px solid #d8d9dc;
        // padding-bottom: 15px;
        // margin-top: 20px;
        margin-top: 41px;
        font-size: 16px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
      }

      .avatarContentUrl {
        margin-top: 15px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .newContentArticle {
        // margin-top: 20px;
        font-variant: tabular-nums;
        list-style: none;
        font-feature-settings: "tnum", "tnum";

        font-size: 16px;
        font-family:
          PingFangSC,
          PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
        margin-top: 40px;

        p {
          margin-top: 0;
          // margin-bottom: 1em;
          margin-bottom: 40px;
        }
      }
    }
  }
}
