.life_container_mobile {
    display: flex;
    background: #f8f8f8;
    align-items: center;
    flex-direction: column;
    max-width: 430px;
    padding-bottom: 30px;
    .life_title {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        line-height: 28px;
    }
    .life_title_desc {
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
    }
    .life_content_bg {
        display: flex;
        flex-direction: column;
        align-items: center;
        // width: 375px;
        .life_slider_bg {
            input {
                display: none;
            }
            margin-left: 15px;
            margin-right: 15px;
            position: relative;
            display: flex;
            margin-top: 30px;
            align-items: center;
            border-radius: 26px;
            height: 32px;
            color: #000000;
            // width: 375px;
            overflow: hidden;
            label {
                width: 57.5px;
                height: 100%;
                border-radius: 26px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 1;
                letter-spacing: -1px;
                font-size: 12px;
                font-weight: 600;
                color: #333333;
            }
            .selection {
                display: flex;
                position: absolute;
                height: 100%;
                width: 57.5px;
                z-index: 0;
                left: 0;
                top: 0;
                border-radius: 26px;
            }
            label:has(input:checked) {
                color: #fff;
            }
            label:has(input:checked) ~ .selection {
                background-color: #1c5db9;
            }
            @for $i from 1 through 6 {
                label:nth-child(#{$i}):has(input:checked) ~ .selection {
                    transform: translateX(calc(57.5px * (#{$i - 1})));
                }
            }
        }

        .life_content {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            justify-content: center;
            // width: 345px;
            margin-left: 15px;
            margin-right: 15px;
            .life_content_img_bg {
                width: 100%;
                height: 208px;
                overflow: hidden;
                flex-shrink: 0;
                text-align: center;
                .life_content_img {
                    background-position: center;
                    background-size: cover;
                }
            }
            .life_content_title {
                margin-left: 10px;
                margin-top: 30px;
                font-size: 18px;
                font-weight: 600;
                color: #333333;
            }
            .life_content_desc {
                margin-left: 10px;
                font-size: 13px;
                font-weight: 400;
                color: #333333;
                margin-top: 16px;
                font-family:
                    PingFangSC,
                    PingFang SC;
            }
        }
    }
}
