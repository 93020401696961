.CustomerCase_mobile {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 40px;
    width: 345px;
    // max-width: 430px;

    .customerCaseTitle {
        font-weight: bold;
        text-align: center;
        font-size: 20px;

        font-weight: 600;
        color: #333333;
        margin-bottom: 8px;
    }

    .customerCaseContent {
        .ani {
            width: 100%;
            overflow: hidden;
            position: relative;
            height: 80px;
        }
    }
}
