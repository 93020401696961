.SolutionIndexPage {
  .title {
    font-size: 26px;
    color: #333333;
    font-weight: bold;
    margin-top: 50px;
    text-align: center;
  }

  .headTitle {
    font-size: 15px;
    color: #333333;
    line-height: 21px;
    text-align: center;
    margin-top: 8px;
  }

  .campusPayment {
    .campusPaymentContent {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;

      .item {
        width: 285px;
        height: 300px;
        color: #fff;
        text-align: center;
        font-size: 24px;
        line-height: 300px;
        font-weight: bold;
        position: relative;

        .hover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #1c5db9;
          opacity: 0;
          transition: all .5s ease;
          padding-top: 110px;

          p {
            font-size: 16px;
            line-height: 27px;
            color: #ffffff;
          }
        }


        &:nth-child(1) {
          background: url("../../../../../assets/images/school_img02@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(2) {
          background: url("../../../../../assets/images/school_img03@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(3) {
          background: url("../../../../../assets/images/school_img04@2x.png") no-repeat center center / 100% 100%;
        }

        &:hover {
          .hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .intelligentCommunication {
    .intelligentCommunicationContent {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        width: 439px;
        height: 242px;
        margin-top: 25px;
        text-align: center;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        line-height: 242px;
        position: relative;

        .hover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background: #1c5db9;
          transition: all .5s ease;

          h1 {
            margin-top: 89px;
            font-size: 24px;
            font-weight: bold;
            line-height: 33px;
          }

          p {
            margin-top: 10px;
            font-size: 15px;
            color: #ffffff;
            line-height: 21px;
          }

        }

        &:nth-child(1) {
          background: url("../../../../../assets/images/school_img05@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(2) {
          background: url("../../../../../assets/images/school_img06@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(3) {
          background: url("../../../../../assets/images/school_img07@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(4) {
          background: url("../../../../../assets/images/school_img08@2x.png") no-repeat center center / 100% 100%;
        }

        &:hover {
          .hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .intelligentLogistics {
    .intelligentLogisticsContent {
      margin-top: 10px;

      .item {
        height: 94px;
        padding-left: 256+40px;
        margin-top: 30px;
        padding-top: 19px;

        .contentTitle {
          font-size: 22px;
          color: #333333;
          font-weight: bold;
          line-height: 30px;
        }

        .text {
          font-size: 15px;
          color: #333333;
          line-height: 21px;
          margin-top: 5px;
        }

        &:nth-child(1) {
          background: #f2f3f5 url("../../../../../assets/images/school_img09@2x.png") no-repeat left top / auto 100%;
        }

        &:nth-child(2) {
          background: #f2f3f5 url("../../../../../assets/images/school_img10@2x.png") no-repeat left top / auto 100%;
        }

        &:nth-child(3) {
          background: #f2f3f5 url("../../../../../assets/images/school_img11@2x.png") no-repeat left top / auto 100%;
        }

        &:nth-child(4) {
          background: #f2f3f5 url("../../../../../assets/images/school_img12@2x.png") no-repeat left top / auto 100%;
        }
      }
    }
  }


}
