.layoutComponent {
  // margin-top: 80px;
  background: #fff;

  .layoutContent {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    .sidebar {
      position: fixed;
      top: 460px;
      width: 240px;
      background-color: #ffffff;
      border: solid 1px #f2f3f5;
      height: auto;

      .item {
        display: block;
        width: 100%;
        height: 62px;
        position: relative;
        text-align: center;
        line-height: 62px;
        padding-left: 5px;
        font-size: 16px;
        color: #333333;
        margin-bottom: 1px;
        transition: background 0.5s linear;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 5px;
          height: 62px;
          background-color: transparent;
        }

        &.active {
          color: #1c5db9;
          background: #f4f5fc;
          font-weight: bold;

          &:before {
            transition: background-color 0.5s linear;
            background-color: #1c5db9;
          }
        }

        &:hover {
          transition: none;
          background: #f4f5fc;
        }
      }
    }

    .occupy {
      width: 300px;
    }

    .childContent {
      flex: 1;
      min-height: 600px;
    }
  }
}
