.K12ServiceCentrePage {
  .headTitle {
    font-size: 34px;
    letter-spacing: 1px;
    color: #333333;
    font-weight: bold;
    text-align: center;
    line-height: 34px;
  }

  .headInfo {
    font-size: 15px;
    color: #333333;
    text-align: center;
    line-height: 16px;
    margin-top: 27px;
  }

  .delayManagementAfterClass {
    height: 446px;
    margin-top: 46px;
    background: url("../../../../../assets/images/k12_bg1@2x.png") no-repeat center center / 100% 100%;
    padding-top: 65px;

    .title {
      font-size: 26px;
      letter-spacing: 1px;
      color: #ffffff;
      line-height: 26px;
      font-weight: bold;
      text-align: center;
    }

    .info {
      font-size: 15px;
      color: #ffffff;
      line-height: 16px;
      text-align: center;
      margin-top: 16px;
    }

    .delayManagementAfterClassContent {
      margin-top: 44px;
      display: flex;
      padding-left: 45px;

      .item {
        width: 193px;
        height: 226px;
        position: relative;
        background: rgba(255, 255, 255, .8);
        margin-right: 13px;

        .itemContent, .hover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;

          &.itemContent {
            .icon {
              width: 50px;
              height: 50px;
              margin: 60px auto 30px;
            }

            .text {
              font-size: 22px;
              line-height: 22px;
              color: #333333;
              font-weight: bold;
              text-align: center;
            }
          }

          &.hover {
            background: #1c5db9;
            padding: 70px 38px 0;
            font-size: 13px;
            line-height: 22px;
            color: #ffffff;
            opacity: 0;
            transition: all .5s ease;
            text-align: left;
          }
        }

        &:nth-child(1) {
          .itemContent {
            .icon {
              background: url("../../../../../assets/images/k12_icon_lianhe@2x.png") no-repeat center center / 100% 100%;
            }
          }
        }

        &:nth-child(2) {
          .itemContent {
            .icon {
              background: url("../../../../../assets/images/k12_icon_tuoguan@2x.png") no-repeat center center / 100% 100%;
            }
          }

        }

        &:nth-child(3) {
          .itemContent {
            .icon {
              background: url("../../../../../assets/images/k12_icon_qiandao@2x.png") no-repeat center center / 100% 100%;
            }
          }
        }

        &:nth-child(4) {
          .itemContent {
            .icon {
              background: url("../../../../../assets/images/k12_icon_anquan@2x.png") no-repeat center center / 100% 100%;
            }
          }

        }


        &:hover {
          .hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .campusCateringSystem {
    .title {
      line-height: 26px;
      font-size: 26px;
      letter-spacing: 1px;
      color: #333333;
      text-align: center;
      margin-top: 55px;
      font-weight: bold;
    }

    .info {
      text-align: center;
      height: 16px;
      font-size: 15px;
      color: #333333;
      margin-top: 16px;
    }

    .campusCateringSystemContent {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 55px;

      .item {
        width: 50%;
        display: flex;
        align-items: center;
        height: 75px;
        margin-bottom: 60px;

        .icon {
          width: 65px;
          height: 75px;
        }

        .box {
          height: 75px;
          flex: 1;
          margin-left: 44px;

          .inlineTitle {
            font-size: 22px;
            letter-spacing: 1px;
            color: #333333;
            line-height: 22px;
            font-weight: bold;
          }

          .text {
            font-size: 15px;
            color: #333333;
            margin-top: 12px;
            width: 287px;
          }
        }

        &:nth-child(1) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_online@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(2) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_safe@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(3) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_manage@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(4) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_custom@2x.png") no-repeat center center / 100% 100%;
          }
        }
      }
    }
  }

  .cloudParents {
    .banner {
      margin-top: 12px;
      height: 194px;
      background: url("../../../../../assets/images/k12_bg2@2x.png") no-repeat center center / 100% 100%;
      padding-top: 65px;

      .title {
        font-size: 26px;
        letter-spacing: 1px;
        color: #ffffff;
        text-align: center;
        font-weight: bold;
      }

      .info {
        font-size: 15px;
        color: #ffffff;
        line-height: 16px;
        text-align: center;
        margin-top: 16px;
      }
    }

    .cloudParentsContent {
      display: flex;
      justify-content: space-between;
      margin-top: 17px;

      .item {
        width: 209px;
        height: 305px;
        background-color: #f2f3f5;
        overflow: hidden;

        .icon {
          width: 59px;
          height: 69px;
          background: gray;
          margin: 95px auto 0;
          transition: all 0.5s ease;
        }

        .text {
          font-size: 22px;
          letter-spacing: 1px;
          color: #333333;
          font-weight: bold;
          line-height: 22px;
          margin-top: 25px;
          text-align: center;
        }

        .hover {
          opacity: 0;
          margin-top: 43px;
          position: relative;
          height: 106px;
          transition: all 0.5s ease;

          .textBox {
            padding: 21px 25px;
            background: #1c5db9;
            font-size: 15px;
            color: #ffffff;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
          }

          &:before {
            display: block;
            content: '';
            position: absolute;
            left: 50%;
            top: -20px;
            width: 600px;
            height: 600px;
            background-color: #1c5db9;
            border-radius: 300px;
            margin-left: -300px;
          }
        }

        &:nth-child(1) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_jianke@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(2) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_notice@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(3) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_account@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(4) {
          .icon {
            background: url("../../../../../assets/images/k12_icon_activity@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &.active, &:hover {
          .icon {
            margin-top: 95-56px;
          }

          .hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .qualityDevelopmentService {
    .title {
      line-height: 26px;
      font-size: 26px;
      letter-spacing: 1px;
      color: #333333;
      text-align: center;
      margin-top: 75px;
      font-weight: bold;
    }

    .info {
      text-align: center;
      height: 16px;
      font-size: 15px;
      color: #333333;
      margin-top: 16px;
    }

    .qualityDevelopmentServiceContent {
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .item {
        width: 440px;
        height: 242px;
        background: gray;
        margin-bottom: 25px;
        position: relative;
        font-size: 22px;
        letter-spacing: 1px;
        color: #ffffff;
        line-height: 242px;
        text-align: center;
        font-weight: bold;

        .hover {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #1c5db9;
          opacity: 0;
          transition: all .5s ease;

          .title {
            line-height: 22px;
            margin: 75px 0 24px;
            color: #fff;
          }

          .info {
            font-size: 15px;
            color: #ffffff;
            line-height: 21px;
            width: 318px;
            margin: 0 auto;
            text-align: left;
            font-weight: normal;
          }
        }

        &:nth-child(1) {
          background: url("../../../../../assets/images/k12_img1@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(2) {
          background: url("../../../../../assets/images/k12_img2@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(3) {
          background: url("../../../../../assets/images/k12_img3@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(4) {
          background: url("../../../../../assets/images/k12_img4@2x.png") no-repeat center center / 100% 100%;
        }

        &:hover {
          .hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .readingAbilityAssessment {
    margin-bottom: 110px;

    .title {
      line-height: 26px;
      font-size: 26px;
      letter-spacing: 1px;
      color: #333333;
      text-align: center;
      font-weight: bold;
      margin-top: 50px;
    }

    .info {
      text-align: center;
      height: 16px;
      font-size: 15px;
      color: #333333;
      margin-top: 16px;
    }

    .readingAbilityAssessmentContent {
      margin-top: 40px;
      height: 400px;
      display: flex;
      background: #f2f3f5;

      .icon {
        width: 209px;
        background: url("../../../../../assets/images/k12_img5@2x.png") no-repeat center center / 100% 100%;
      }

      .list {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 40px 28px;

        .item {
          display: flex;
          height: 48px;

          .icon {
            width: 42px;
            height: 48px;
            background: gray;
          }

          .box {
            margin-left: 23px;
            flex: 1;

            .inlineTitle {
              font-size: 22px;
              color: #333333;
              font-weight: bold;
              line-height: 22px;
            }

            .inlineInfo {
              line-height: 16px;
              font-size: 15px;
              color: #333333;
              margin-top: 11px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          &:nth-child(1) {
            .icon {
              background: url("../../../../../assets/images/k12_icon_jz@2x.png") no-repeat center center / 100% 100%;
            }
          }

          &:nth-child(2) {
            .icon {
              background: url("../../../../../assets/images/k12_icon_qw@2x.png") no-repeat center center / 100% 100%;
            }
          }

          &:nth-child(3) {
            .icon {
              background: url("../../../../../assets/images/k12_icon_qm@2x.png") no-repeat center center / 100% 100%;
            }
          }

          &:nth-child(4) {
            .icon {
              background: url("../../../../../assets/images/k12_icon_zy@2x.png") no-repeat center center / 100% 100%;
            }
          }
        }
      }
    }
  }
}
