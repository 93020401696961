.homePage {
  background: #f8f8f8;
  @keyframes scrollBarAni {
    0% {
      top: 0;
      opacity: 1;
    }
    35% {
      top: 50%;
      opacity: 0;
    }
    65% {
      top: -50%;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }

  .topBannerModular {
    width: 100%;
    // min-width: 1200px;
    height: 100vh;

    video {
      display: flex;
      width: 100%;
      height: 100vh;
      min-height: 600px;
      object-fit: cover;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
    }
    .video_mask {
      position: absolute;
      width: 100%;
      height: 100vh;
      min-height: 600px;
      object-fit: cover;
      border: none;
      outline: none;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
    .bgImg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;

      .img {
        width: 675px;
        height: 55px;
        background: url("../../../../assets/images/home_top_img_1@3x.png") no-repeat center center / 100% 100%;
      }
      .bgTitle {
        font-size: 42px;
        font-weight: bold;
        color: #ffffff;
        line-height: 55px;
        letter-spacing: 3px;
        // -webkit-text-stroke: 1px #ffffff;
        // text-stroke: 1px #ffffff;
        transition: opacity 0.5s ease;
      }
    }

    .scrollBar {
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 30px;
      margin-left: -15px;
      z-index: 20;

      .iconBox {
        height: 40px;

        .icon {
          width: 22px;
          height: 22px;
          background: url("../../../../assets/images/roll@2x.png") no-repeat center center / 100% 100%;
          animation: scrollBarAni 1.5s ease-out infinite;
          position: relative;
          top: 50%;
          margin: -10px auto 0;
        }
      }

      .text {
        line-height: 17px;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0;
        font-weight: normal;
        text-align: center;
      }
    }
  }

  .aboutModular {
    background: #fff;
    background: url("../../../../assets/images/ABOUT@2x.png") no-repeat center 50px;
    padding-top: 102px;

    .info {
      width: 680px;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 50px;
      color: #353535;
      text-align: center;
      margin: 0 auto 70px;
    }

    .numBox {
      background: url("../../../../assets/images/home_bg2@2x.png") no-repeat center center / 100% 100%;

      .numBoxContent {
        display: flex;
        height: 200px;
        align-items: center;
        justify-content: center;

        .item {
          width: auto;
          text-align: center;

          .top {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            .txt {
              font-size: 60px;
              line-height: 50px;
              color: #333333;
              font-weight: bold;
              display: block;
              margin: 0;
              padding: 0;
            }

            span {
              font-size: 15px;
              line-height: 30px;
              color: #333333;
              font-weight: normal;
              margin-left: 6px;
            }
          }

          .bottom {
            margin-top: 30px;
            font-size: 18px;
            color: #333333;
          }

          .des {
            font-size: 14px;
            color: #919191;
            margin-top: 15px;
            height: 16px;
          }

          &:nth-child(1) {
            width: 180px;
            margin-right: 270px;
          }

          //
          &:nth-child(2) {
            width: 390px;
            //margin-left: 10px;
          }

          //
          //&:nth-child(3) {
          //  width: 272px;
          //}
        }
      }
    }

    .businessIntroduce {
      .item {
        margin-top: 80px;
        width: 1200px;
        height: 420px;
        position: relative;
        background: url("../../../../assets/images/home_img1@2x.png") no-repeat left top / auto 100%;
        transition: all 0.5s linear;

        .info {
          position: absolute;
          right: 0;
          top: 60px;
          width: 520px;
          height: 300px;
          background-color: #fbfbfb;
          box-shadow: 5px 5px 12px 0 rgba(27, 53, 89, 0.08);
          padding-left: 110px;
          padding-top: 94px;
          text-align: left;
          transition: all 0.5s linear;

          .title {
            font-size: 34px;
            line-height: 50px;
            color: #333333;
            font-weight: bold;
          }

          .line {
            margin-top: 15px;
            width: 60px;
            height: 2px;
            background-color: #1c5db9;
          }

          .text {
            margin-top: 21px;
            font-size: 17px;
            color: #333333;
            line-height: 30px;
            width: 340px;
          }
        }

        &:hover {
          background-position: -20px 0;

          .info {
            right: -15px;
          }
        }

        &:nth-child(even) {
          background-position: right top;

          .info {
            right: 1200-520px;
          }

          &:hover {
            background-position: 1215-720px top;

            .info {
              right: 1200-520+15px;
            }
          }
        }

        &:nth-child(2) {
          background-image: url("../../../../assets/images/home_img2@2x.png");
        }

        &:nth-child(3) {
          background-image: url("../../../../assets/images/home_img3@2x.png");
        }
      }
    }
  }

  .empowermentModular {
    background: #0e131c url("../../../../assets/images/home_bg3@2x.png") no-repeat center center / 100% 100%;

    .empowermentContent {
      margin-top: 100px;
      display: flex;
      height: 260px;
      align-items: center;
      justify-content: space-between;
      width: 600px;

      .item {
        transition: all 0.5s linear;

        .icon {
          width: 70px;
          height: 70px;
          background: url("../../../../assets/images/icon_medical@2x.png") no-repeat center center / 100% 100%;
          margin: 0 auto;
        }

        .text {
          font-size: 26px;
          color: #ffffff;
          margin-top: 31px;
          text-align: center;
        }

        &:nth-child(2) {
          .icon {
            background-image: url("../../../../assets/images/icon_finance@2x.png");
          }
        }

        &:nth-child(3) {
          .icon {
            background-image: url("../../../../assets/images/icon_public@2x.png");
          }
        }
      }
    }
  }

  //k12背景
  .k12_modular {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 640px;
    margin-top: 100px;
    background-image: url("../../../../assets/home/home_bg.png");
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    .k12_modular_content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      height: 100%;
      margin-right: 30px;
      .title {
        // width: 789px;
        margin-top: 203px;
        font-size: 48px;
        // font-family:
        //   PingFangSC,
        //   PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 67px;
        letter-spacing: 2px;
      }
      .sub_title {
        max-width: 940px;
        font-size: 18px;
        margin-top: 70px;
        // font-family:
        //   PingFangSC,
        //   PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1px;
      }
      .more_bg {
        align-items: center;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
        margin-top: 60px;
        cursor: pointer;
        .more_title {
          font-size: 16px;
          // font-family:
          //   PingFangSC,
          //   PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 25px;
          transition: transform 0.3s ease;
        }
        .more_img {
          margin-left: 5px;
          width: 14px;
          height: 14px;
          background-image: url("../../../../assets/home/more_white.png");
          background-size: cover;
          background-position: 0 0;
          background-repeat: no-repeat;
          transition: transform 0.3s ease;
        }
        &:hover {
          .more_title {
            transform: translateX(5px);
          }
          .more_img {
            transform: translateX(10px);
          }
        }
      }
    }
  }
}