.SocialWelfarePage {
  background: #f8f8f8;
  @keyframes scrollBarAni {
    0% {
      top: 0;
      opacity: 1;
    }
    35% {
      top: 50%;
      opacity: 0;
    }
    65% {
      top: -50%;
      opacity: 0;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  // padding-top: 80px;

  //top
  .topBannerModular {
    width: 100%;
    height: 100vh;
    .video_bg {
      display: flex;
      width: 100%;
      height: 100vh;
      object-fit: cover;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
    }
    .video_mask {
      position: absolute;
      width: 100%;
      height: 100vh;
      // min-height: 600px;
      object-fit: cover;
      border: none;
      outline: none;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
    }
    .bgImg {
      position: absolute;
      color: #fff;
      text-shadow: 1px 1px 2px #666;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 10;
      ._title {
        font-size: 2.5rem;
        font-weight: 800;
        margin-bottom: 0.3125rem;
        transition: opacity 0.5s ease;
      }
      ._des {
        font-size: 1.5rem;
        transition: opacity 0.5s ease;
      }
    }
  }

  .banner-box {
    height: 320px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .statistics {
    height: 237px;
    background-color: #fbfbfb;
    padding-top: 20px;
    padding-bottom: 20px;
    .content {
      width: 1440px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      .item {
        width: 360px;
        height: 100%;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        user-select: none;
        .number {
          display: flex;
          align-items: flex-start;
          .count {
            font-family: PingFangSC-Semibold;
            font-size: 50px;
            font-weight: bold;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #333333;
          }
          .suffix {
            font-size: 16px;
          }
        }
        .label {
          font-family: PingFangSC-Regular;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #333333;
          margin-top: 6px;
        }
        &:not(:last-child)::after {
          content: "";
          width: 1px;
          height: 30px;
          background-color: #d8d8d8;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .projects {
    background-color: #ffffff;
    padding-top: 80px;
    .layer-title {
      width: 1440px;
      margin: 0 auto;
      font-family: PingFangHK-Semibold;
      font-size: 38px;
      font-weight: bold;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #333333;
      text-align: center;
    }
    .adapted_box {
      width: 1440px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 690px;
        height: 621px;
        margin-bottom: 40px;
        background-color: #ffffff;
        box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.06);
        cursor: pointer;
        user-select: none;
        position: relative;
        .img {
          width: 100%;
          height: 415px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform-origin: center center;
            transition: all 0.5s ease;
            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .info {
          padding: 30px;
          overflow: hidden;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: auto;
          max-height: 166px;
          background-color: #fff;
          transition: max-height 0.25s ease-in-out;
          &:hover {
            max-height: 278px;
            .desc {
              -webkit-line-clamp: 10;
            }
          }
          .title {
            font-size: 24px;
            font-family: PingFangHK, PingFangHK;
            font-weight: 600;
            color: #333333;
            line-height: 33px;
          }
          .desc {
            margin-top: 20px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            font-family:
              PingFangSC,
              PingFang SC;
            font-weight: 400;
          }
        }
      }
    }
  }
  .expertTeam {
    padding: 40px 0 50px;
    background-color: #ffffff;
    .layer-title {
      width: 1440px;
      margin: 0 auto;
      text-align: center;
      .title {
        font-family: PingFangHK-Semibold;
        font-size: 38px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #333333;
      }
    }
    .cont {
      width: 1440px;
      margin: 70px auto 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item {
        width: 700px;
        height: 292px;
        background-color: #f4f5f7;
        margin-bottom: 30px;
        display: flex;
        .left {
          width: 212px;
          height: 100%;
          position: relative;
          flex-shrink: 0;
          img {
            width: 150px;
            height: 200px;
            pointer-events: none;
            position: absolute;
            left: 50px;
            top: 40px;
            z-index: 10;
          }
          &::after {
            content: "";
            box-sizing: border-box;
            width: 40px;
            height: 40px;
            border: 6px solid #1c5db9;
            position: absolute;
            bottom: 40px;
            right: 0px;
            z-index: 0;
          }
        }
        .right {
          padding: 58px 43px 0;
          .name {
            font-family: PingFangSC-Medium;
            font-size: 22px;
            font-weight: 500;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #1c5db9;
          }
          .position {
            margin-top: 23px;
            font-family: PingFangSC-Regular;
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 28px;
            letter-spacing: 0px;
            color: #333333;
          }
        }
      }
    }
  }
  .memorabilia {
    background-color: #fff;
    background-image: url(../../../../assets/images/events_bg@2x.png);
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
    .content {
      width: 1440px;
      margin: 0 auto;
      padding: 50px 70px;
      .layer-title {
        font-family: PingFangHK-Semibold;
        font-size: 38px;
        font-weight: bold;
        font-stretch: normal;
        letter-spacing: 0px;
        color: rgba(51, 51, 51, 0.9);
        text-align: center;
        padding: 20px 0;
      }
    }
  }
  .linkTab {
    padding-top: 80px;
    background-color: #fff;
    .content {
      width: 1440px;
      margin: 0 auto;
    }
  }
}
