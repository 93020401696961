.partnerCase_mobile {
    width: 100%;
    .partnerCase_items_mobile {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        .partnerCase_item_mobile {
            font-size: 22px;
            font-family:
                PingFangSC,
                PingFang SC;
            font-weight: 600;
            color: #1b1f26;
            margin-top: 40px;
            margin-bottom: 20px;
        }
    }

    .partnerCaseContent_mobile {
        margin-bottom: 30px;
        .ani_mobile {
            width: 100%;
            overflow: hidden;
            position: relative;
            height: 60px;
        }
    }
}
