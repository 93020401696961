.nc_container_mobile {
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;

    .nc_title_mobile {
        z-index: 1;
        font-size: 22px;
        font-family:
            PingFangSC,
            PingFang SC;
        font-weight: 600;
        color: #1b1f26;
        line-height: 36px;
        margin-top: 30px;
        margin-bottom: 20px;
        text-align: center;
    }

    .nc_small_item_mobile {
        position: relative;
        display: flex;
        // width: 345px;
        height: 445px;
        border-radius: 3px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 20px;
        overflow: hidden;
        flex-direction: column;
        .nc_small_item_img_bg_mobile {
            width: 100%;
            height: 228px;
            overflow: hidden;
            .nc_small_item_img_mobile {
                width: 100%;
                background-position: center;
                background-size: cover;
            }
        }

        .nc_small_item_bottom_mobile {
            display: flex;
            width: 100%;
            flex: 1;
            background-color: white;
            flex-direction: column;

            .title_mobile {
                font-size: 16px;
                flex: 1;
                font-family:
                    PingFangSC,
                    PingFang SC;
                font-weight: 500;
                margin-top: 30px;
                margin-left: 15px;
                margin-right: 15px;
                color: #333333;
                line-height: 22px;
                border-bottom: 0.5px solid hsla(0, 0%, 60%, 0.58);
            }
            .time_bg_mobile {
                display: flex;
                flex-direction: row;
                margin-left: 15px;
                margin-right: 15px;
                height: 60px;
                align-items: center;
                justify-content: space-between;
                .time_mobile {
                    font-size: 14px;
                    font-family:
                        PingFangSC,
                        PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 20px;
                }
                .time_more_img_mobile {
                    margin-left: 5px;
                    width: 12px;
                    height: 12px;
                    background-image: url("../../../../assets/home/more.png");
                    background-size: cover;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                }
            }

            .more_bg_mobile {
                position: absolute;
                right: 30px;
                bottom: 37px;
                align-items: center;
                justify-content: flex-end;
                display: flex;
                flex-direction: row;
                cursor: pointer;
                .more_title_mobile {
                    font-size: 15px;
                    font-family:
                        PingFangSC,
                        PingFang SC;
                    font-weight: 400;
                    color: #919191;
                    line-height: 21px;
                }
                .more_img_mobile {
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                    background-image: url("../../../../assets/home/more.png");
                    background-size: cover;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}
