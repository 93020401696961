.EducationalInnovationPage {
  .headTitle {
    font-size: 34px;
    line-height: 34px;
    letter-spacing: 1px;
    color: #333333;
    font-weight: bold;
    text-align: center;
  }

  .headInfo {
    font-size: 15px;
    color: #333333;
    line-height: 16px;
    margin-top: 27px;
    text-align: center;
  }

  .qylLibrary {
    height: 471px;
    margin-top: 46px;
    background: gray;
    padding-top: 56px;
    background: url("../../../../../assets/images/edu_bg@2x.png") no-repeat center center / 100% 100%;

    .title {
      font-size: 26px;
      color: #ffffff;
      line-height: 27px;
      font-weight: bold;
      text-align: center;
    }

    .info {
      font-size: 15px;
      color: #ffffff;
      line-height: 16px;
      text-align: center;
      margin-top: 15px;
    }

    .qylLibraryContent {
      padding-left: 47px;
      margin-top: 58px;
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 380px;
        height: 101px;
        background-color: #ffffff;
        border-left: 8px solid #1c5db9;
        padding-left: 30px;
        padding-top: 15px;
        margin-right: 46px;
        margin-bottom: 36px;

        .top {
          height: 30px;
          display: flex;
          align-items: center;

          .icon {
            width: 30px;
            height: 30px;
            background: gray;
          }

          .inlineTitle {
            font-size: 22px;
            color: #333333;
            font-weight: bold;
            margin-left: 11px;
          }
        }

        .text {
          font-size: 13px;
          line-height: 18px;
          color: #333333;
          margin-top: 10px;
          width: 325px;

        }

        &:nth-child(1) {
          .top > .icon {
            background: url("../../../../../assets/images/edu_icon_guanli@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(2) {
          .top > .icon {
            background: url("../../../../../assets/images/edu_icon_read@2x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(3) {
          .top > .icon {
            background: url("../../../../../assets/images/edu_icon_phone@3x.png") no-repeat center center / 100% 100%;
          }
        }

        &:nth-child(4) {
          .top > .icon {
            background: url("../../../../../assets/images/edu_icon_fenxi@3x.png") no-repeat center center / 100% 100%;
          }
        }
      }
    }
  }

  .famousTeacherResources {
    height: 392px;
    background-color: #f2f3f5;
    display: flex;
    margin-top: 72px;

    .left {
      width: 376px;
      padding-left: 47px;
      padding-top: 74px;

      .title {
        font-size: 26px;
        color: #333333;
        line-height: 26px;
        font-weight: bold;
      }

      .info {
        font-size: 15px;
        line-height: 18px;
        color: #333333;
        margin-top: 23px;
      }

      .text {
        width: 311px;
        font-size: 15px;
        line-height: 24px;
        color: #333333;
        margin-top: 89px;
      }
    }

    .right {
      flex: 1;
      background: url("../../../../../assets/images/edu_img1@2x.png") no-repeat left top / 516px 375px;
    }
  }

  .intelligentCompositionCorrection {
    margin-bottom: 53px;

    .title {
      line-height: 26px;
      font-size: 26px;
      letter-spacing: 1px;
      color: #333333;
      margin-top: 67px;
      text-align: center;
      font-weight: bold;
    }

    .headInfo {
      font-size: 15px;
      color: #333333;
      line-height: 16px;
      margin-top: 15px;
    }

    .intelligentCompositionCorrectionContent {
      margin-top: 42px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 439px;
        height: 242px;
        text-align: center;
        line-height: 242px;
        position: relative;
        font-size: 22px;
        letter-spacing: 1px;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 25px;

        .hover {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #1c5db9;
          transition: all .5s ease;

          h1 {
            font-size: 22px;
            letter-spacing: 1px;
            color: #ffffff;
            font-weight: bold;
            line-height: 22px;
            margin-top: 75px;
          }

          p {
            font-size: 15px;
            color: #ffffff;
            line-height: 21px;
            width: 318px;
            margin: 25px auto 0;
            text-align: left;
          }
        }

        &:nth-child(1) {
          background: url("../../../../../assets/images/edu_img2@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(2) {
          background: url("../../../../../assets/images/edu_img3@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(3) {
          background: url("../../../../../assets/images/edu_img4@2x.png") no-repeat center center / 100% 100%;
        }

        &:nth-child(4) {
          background: url("../../../../../assets/images/edu_img5@2x.png") no-repeat center center / 100% 100%;
        }

        &:hover {
          .hover {
            opacity: 1;
          }
        }
      }
    }
  }
}
